import React, { useCallback } from 'react';
import useHttpClient from '../../hooks/useHttpClient';
import { HTTP_ACTION } from '../../utils/constants';
import Alert from '../alert';
import TicketFields from './ticketFields';

const TicketList = ({ raffle, readOnly = true }) => {
  const { httpRequest: updateTicket, error: updateTicketError } = useHttpClient({
    httpAction: HTTP_ACTION.PUT,
  });

  const handleUpdateTicket = useCallback(
    async (formVals, ticket, setNewTicket) => {
      const owner = formVals.owner !== '' ? formVals.owner : null;

      // if the owner didnt change then dont change the requester
      // if it did change then make the requester the new owner
      const requester = ticket.owner === owner ? ticket.requester : owner;

      const updateResponse = (
        await updateTicket({
          url: `/raffle-service/raffles/${raffle?.id}/tickets/${ticket.id}`,
          requestBody: {
            ...formVals,
            paid: formVals.paid && owner !== null,
            id: ticket.id,
            version: ticket.version,
            requester,
            owner,
          },
        })
      ).response;

      if (updateResponse?.data) {
        setNewTicket(updateResponse.data);
      }
    },
    [raffle, updateTicket],
  );

  return (
    <>
      <Alert show={updateTicketError} severity='error' bottomMargin={2}>
        Error updating ticket: {updateTicketError}
      </Alert>
      {raffle?.tickets?.map((ticket) => (
        <TicketFields
          key={ticket.id}
          initialTicket={ticket}
          handleUpdateTicket={handleUpdateTicket}
          readOnly={readOnly}
        />
      ))}
    </>
  );
};

export default TicketList;
