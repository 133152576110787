export const convertToNumber = (val) => {
  try {
    if (val.includes('.')) {
      return parseFloat(val);
    } else {
      return parseInt(val);
    }
  } catch (e) {
    return undefined;
  }
};
export const convertToUtc = (val) => {
  try {
    if (!val) {
      return undefined;
    }

    return new Date(val).toISOString();
  } catch (e) {
    return undefined;
  }
};

export const convertToFriendlyDate = (val) => {
  try {
    if (!val) {
      return undefined;
    }
    return new Date(val).toLocaleString([], {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  } catch (e) {
    return undefined;
  }
};

export const convertUtcStringToCurrentTimezone = (val) => {
  try {
    if (!val) {
      return undefined;
    }
    const date = new Date(val);
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  } catch (e) {
    return undefined;
  }
};

export const convertUtcStringToFriendlyCurrentTimezone = (val) => {
  return convertToFriendlyDate(convertUtcStringToCurrentTimezone(val));
};

export const emailTest = (value) => {
  const emailRegEx = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return emailRegEx.test(value.toLowerCase()) !== true ? 'Invalid email' : null;
};
