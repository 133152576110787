import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../confirmationDialog';

const ChooseWinnersDialog = ({ isOpen, handleClose, tickets, numWinners, chooseWinners, complete }) => {
  const [isRaffleFullAndPaid, setIsRaffleFullAndPaid] = useState(false);

  const handleChooseWinners = async () => {
    chooseWinners();
    handleClose();
  };

  useEffect(() => {
    setIsRaffleFullAndPaid(
      tickets?.filter((ticket) => ticket.owner === undefined || ticket.paid !== true)?.length === 0,
    );
  }, [tickets]);

  return (
    <>
      <ConfirmationDialog
        body='You cannot choose winners on a raffle that is already complete.'
        handleClose={handleClose}
        handleConfirm={handleClose}
        hideCancel={true}
        isOpen={isOpen && complete}
        okText='OK'
        title='Raffle Already Complete!'
      />
      <ConfirmationDialog
        body='You cannot choose winners on a raffle unless it is full and all tickets are paid.'
        handleClose={handleClose}
        handleConfirm={handleClose}
        hideCancel={true}
        isOpen={isOpen && !isRaffleFullAndPaid && !complete}
        okText='OK'
        title='Raffle Not Full And Paid!'
      />
      <ConfirmationDialog
        body={`Click "Choose Winner(s)" to complete the raffle and choose ${numWinners} winner(s).`}
        handleClose={handleClose}
        handleConfirm={handleChooseWinners}
        isOpen={isOpen && isRaffleFullAndPaid && !complete}
        okText='Choose Winners'
        title='Choose Winners And Complete Raffle?'
      />
    </>
  );
};

export default ChooseWinnersDialog;
