import { Badge, IconButton } from '@material-ui/core';
import React, { useCallback, useMemo, useRef } from 'react';
import ChatIcon from '@material-ui/icons/Chat';
import ChatWindow from '../chatWindow';
import { useAuth } from '../../hooks/useAuth';
import { useChat } from '../../hooks/useChat';

const Chat = () => {
  const chatButtonRef = useRef(null);
  const { user } = useAuth();
  const { activeChat, conversations, isChatOpen, setIsChatOpen } = useChat();
  const handleChatClick = useCallback(() => {
    setIsChatOpen((prev) => !prev);
  }, [setIsChatOpen]);

  const handleCloseChat = useCallback(() => {
    setIsChatOpen(false);
  }, [setIsChatOpen]);

  const totalUnread = useMemo(
    () => conversations?.reduce((totalUnread, convo) => totalUnread + (convo.numUnread || 0), 0),
    [conversations],
  );

  return (
    <>
      {user && activeChat?.withUser && (
        <IconButton
          ref={chatButtonRef}
          onClick={handleChatClick}
          color='primary'
          style={{
            position: 'fixed',
            bottom: '0px',
            right: '0px',
          }}>
          <Badge badgeContent={totalUnread} color='secondary' style={{ transform: 'translate(60px, -30px)' }}></Badge>
          <ChatIcon
            style={{
              fontSize: '48px',
              color: 'rgb(255, 255, 255)',
              backgroundColor: 'rgb(25, 118, 210)',
              borderRadius: '28px',
              padding: '9px',
            }}
          />
        </IconButton>
      )}
      {isChatOpen && <ChatWindow closeChat={handleCloseChat} chatButtonRef={chatButtonRef} />}
    </>
  );
};

export default Chat;
