import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAuth } from '../../hooks/useAuth';
import useHttpClient from '../../hooks/useHttpClient';
import { HTTP_ACTION, REGEX_PATTERN } from '../../utils/constants';
import Alert from '../alert';
import ConfirmationDialog from '../confirmationDialog';
import TicketGrid from '../ticketGrid';
const useStyles = makeStyles({
  numRandom: { width: 200, marginRight: 36, marginBottom: 24 },
});

const TicketSelector = ({ raffleId, tickets, onAssign, ticketPrice }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const formMethods = useForm({ defaultValues: { ownerType: 'self' } });
  const { handleSubmit, register, errors, control, watch } = formMethods;
  const [showAssignedDialog, setShowAssignedDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState();

  const ownerType = watch('ownerType');
  const {
    data: assignedTicketData,
    httpRequest: requestTickets,
    error,
    loading,
  } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: `/raffle-service/raffles/${raffleId}/ticket-requests`,
  });

  useEffect(() => {
    if (assignedTicketData) {
      setShowAssignedDialog(true);
    } else {
      setShowAssignedDialog(false);
    }
  }, [assignedTicketData]);

  const onSubmit = async (formvals) => {
    const specificTickets = [];

    const owner = ownerType === 'self' ? user?.username : formvals.owner;
    const numRandom = formvals.numRandom ? parseInt(formvals.numRandom, 10) : 0;
    formvals.ticketCheckboxes.forEach((ticketRequested, i) => ticketRequested && specificTickets.push(i + 1));

    const data = { owner, specificTickets, numRandom, randomIfTaken: formvals.randomIfTaken };
    requestTickets({ requestBody: data });
  };

  const validateOwner = (owner) => {
    return ownerType !== 'self' && !owner ? 'Must Specify Owner' : undefined;
  };

  const validateNumTickets = (numRandomString) => {
    const numSpecific = watch('ticketCheckboxes').filter((ticketSelected) => ticketSelected).length;

    const numRandom = !numRandomString ? 0 : parseInt(numRandomString);

    return numRandom + numSpecific === 0 ? 'Must request at least 1 ticket' : undefined;
  };

  const showConfirmationDialog = (formvals) => {
    const specificTickets = [];
    const numRandom = formvals.numRandom ? parseInt(formvals.numRandom, 10) : 0;
    formvals.ticketCheckboxes.forEach((ticketRequested, i) => ticketRequested && specificTickets.push(i + 1));
    setConfirmationMessage(
      `You are requesting ${numRandom + specificTickets.length} ticket(s): ${numRandom} random${
        specificTickets.length ? ' and ' + specificTickets.join(', ') : ''
      }.${
        formvals.randomIfTaken && specificTickets.length > 0
          ? ' The specific tickets you requested will be replaced with randoms if they are unavailable.'
          : ''
      }`,
    );
    setShowConfirmDialog(true);
  };

  const handleAssignmentDialogClose = () => {
    setShowAssignedDialog(false);
    if (onAssign) {
      onAssign();
    }
  };

  const handleConfirmDialogClose = () => {
    setShowConfirmDialog(false);
  };

  const handleConfirmAssign = () => {
    setShowConfirmDialog(false);
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(showConfirmationDialog)}>
          <Box mb={4} maxWidth='700px' margin='auto'>
            <TicketGrid tickets={tickets} />
          </Box>

          <Alert show={error} severity='error' bottomMargin={2}>
            Error requesting tickets: {error && error.message ? error.message : error}
          </Alert>
          <Typography variant='h6' style={{ textAlign: 'center' }}>
            Who are these tickets for?
          </Typography>
          <Box display='flex' flexDirection='column' alignItems='center'>
            <FormGroup row>
              <Controller
                as={
                  <RadioGroup aria-label='ticket owner' name='ownerTypeGroup' defaultValue='self' row>
                    <FormControlLabel value='self' control={<Radio color='primary' />} label='Myself' />
                    <FormControlLabel value='other' control={<Radio color='primary' />} label='Another User' />
                  </RadioGroup>
                }
                name='ownerType'
                control={control}
              />

              <TextField
                inputRef={register({
                  validate: validateOwner,
                  pattern: { value: REGEX_PATTERN.REG_USERNAME, message: 'Invalid username character' },
                  maxLength: { value: 20, message: 'Max length is 20 characters' },
                  minLength: { value: 3, message: 'min length is 3 characters' },
                })}
                variant='outlined'
                margin='normal'
                id={'owner'}
                label='Ticket Owner'
                name={`owner`}
                disabled={ownerType === 'self'}
                error={!!errors.owner}
                helperText={errors.owner && errors.owner?.message}
              />
            </FormGroup>
            <FormGroup row>
              <TextField
                inputRef={register({
                  validate: validateNumTickets,
                  min: 0,
                  max: tickets?.length,
                })}
                defaultValue={0}
                type='number'
                variant='outlined'
                margin='normal'
                id={'numRandom'}
                label='Random Tickets'
                name={`numRandom`}
                error={!!errors.numRandom}
                helperText={errors.numRandom && errors.numRandom.message}
                className={classes.numRandom}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    inputRef={register}
                    id='randomIfTaken'
                    name='randomIfTaken'
                    color='primary'
                    defaultChecked={true}
                  />
                }
                label='Substitute random for unavailable tickets'
              />
            </FormGroup>
          </Box>
          <Button variant='contained' type='submit' color='primary' fullWidth disabled={loading}>
            Request Tickets
          </Button>
        </form>
      </FormProvider>
      <Dialog
        open={showAssignedDialog}
        onClose={handleAssignmentDialogClose}
        aria-labelledby='assignment-dialog-title'
        aria-describedby='assignment-dialog-description'>
        <DialogTitle id='assignment-dialog-title'>{'Tickets Requested'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='assignment-dialog-description' data-testid='assignment-assigned'>
            {assignedTicketData?.assignedTickets?.length === 0 && (
              <>Sorry, the raffle filled up. We could not assign you any tickets.</>
            )}
            {assignedTicketData?.assignedTickets?.length > 0 && (
              <>
                {ownerType === 'self' ? 'You' : watch('owner')}
                {` got ${assignedTicketData?.assignedTickets?.length} ticket(s) totalling $${
                  assignedTicketData?.assignedTickets?.length * ticketPrice
                }: `}
                [{assignedTicketData?.assignedTickets.join(', ')}]. Please pay promptly and use the "MARK MY TICKETS
                PAID" button at the top of this page to let the raffler know you paid. Good Luck!
              </>
            )}
          </DialogContentText>
          {assignedTicketData?.assignedTickets?.length > 0 && assignedTicketData?.ticketSubstitution && (
            <DialogContentText id='assignment-dialog-random' data-testid='assignment-substitution'>
              Some specific tickets you requested were unavailable and were replaced with randoms.
            </DialogContentText>
          )}

          {assignedTicketData?.assignedTickets?.length > 0 && assignedTicketData?.partialFulfillment && (
            <DialogContentText id='assignment-dialog-random' data-testid='assignment-partial'>
              There were not enough tickets available to fulfill your request. You have been given the maximum
              available.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAssignmentDialogClose} color='primary' autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={showConfirmDialog}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleConfirmAssign}
        title='Process Ticket Request?'
        body={confirmationMessage}
        cancelText='Cancel'
        okText='OK'
      />
    </>
  );
};

export default TicketSelector;
