import { useReducer, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useAuth } from './useAuth';
import { getEnvVariable } from '../utils/envUtils';
import { HTTP_ACTION } from '../utils/constants';

const getErrorMessage = (e) =>
  e?.response?.data?.error ||
  e?.response?.data?.message ||
  (e?.response?.data?.length > 0 && e?.response?.data?.[0]?.message) ||
  e?.response ||
  e?.message ||
  e;

const httpClientReducer = (state, action) => {
  switch (action.type) {
    case 'HTTP_CLIENT_INIT':
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case 'HTTP_CLIENT_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload,
      };
    case 'HTTP_CLIENT_FAILURE':
      return {
        data: undefined,
        loading: false,
        error: action.error,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const configureOptions = (opts, access_token) => {
  const defaultHeaders = {
    Authorization: access_token ? `Bearer ${access_token}` : undefined,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const configuredOptions = {
    ...opts,
  };

  if (opts.headers) {
    configuredOptions.headers = {
      ...defaultHeaders,
      ...opts.headers,
    };
  } else {
    configuredOptions.headers = defaultHeaders;
  }

  return configuredOptions;
};

function useHttpClient({
  httpAction = HTTP_ACTION.GET,
  url,
  useApiGateway = true,
  options,
  initialRequest,
  success,
  failure,
  requestBody,
} = {}) {
  const { user, checkSession } = useAuth();
  const [state, dispatch] = useReducer(httpClientReducer, {
    loading: initialRequest,
    error: null,
    data: null,
  });
  const accessToken = user?.accessToken;

  const makeRequest = useCallback(
    async (uri, opts = {}, handlers, method, body) => {
      const handleError = (e) => {
        if (handlers.failure) {
          handlers.failure(e);
        }

        dispatch({
          type: 'HTTP_CLIENT_FAILURE',
          error: getErrorMessage(e),
        });
      };

      if (!checkSession()) {
        handleError('Session expired, please try again.');
        return { error: getErrorMessage('Session expired, please try again.') };
      }

      const configuredOptions = configureOptions(opts, accessToken);
      dispatch({ type: 'HTTP_CLIENT_INIT' });
      try {
        let fullUri = `${useApiGateway ? getEnvVariable('apiGateway') : ''}${uri}`;
        let response;
        switch (method) {
          case HTTP_ACTION.GET:
            response = await axios.get(fullUri, configuredOptions);
            break;
          case HTTP_ACTION.POST:
            response = await axios.post(fullUri, body, configuredOptions);
            break;
          case HTTP_ACTION.PUT:
            response = await axios.put(fullUri, body, configuredOptions);
            break;
          case HTTP_ACTION.DELETE:
            response = await axios.delete(
              fullUri,
              { headers: configuredOptions.headers, data: body },
              configuredOptions,
            );
            break;
          default:
            throw Error('unsupported HTTP_ACTION');
        }

        dispatch({ type: 'HTTP_CLIENT_SUCCESS', payload: response.data });
        if (handlers.success) {
          handlers.success(response?.data);
        }
        return { response };
      } catch (error) {
        handleError(error);
        return { error: getErrorMessage(error) };
      }
    },
    [checkSession, accessToken, useApiGateway],
  );

  useEffect(() => {
    const handlers = {
      success,
      failure,
    };
    if (initialRequest && url) {
      makeRequest(url, options, handlers, httpAction, requestBody);
    }
  }, [failure, initialRequest, options, success, url, httpAction, makeRequest, requestBody]);

  const httpRequest = useCallback(
    async ({
      httpAction: newHttpAction,
      url: newUrl,
      options: newOptions,
      success: newSuccess,
      failure: newFailure,
      requestBody: newRequestBody,
    } = {}) => {
      const handlers = {
        success: newSuccess || success,
        failure: newFailure || failure,
      };

      return makeRequest(
        newUrl || url,
        newOptions || options,
        handlers,
        newHttpAction || httpAction,
        newRequestBody || requestBody,
      );
    },
    [failure, httpAction, makeRequest, options, requestBody, success, url],
  );

  return {
    ...state,
    httpRequest,
  };
}

export default useHttpClient;
