import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { makeStyles, useTheme } from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

const useLocalStyles = makeStyles((theme) => ({
  centered: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
}));

const Loader = ({ loading, children, overlayText, ...rest }) => {
  const theme = useTheme();
  const { Spinner = ClipLoader, size = 100, color = theme?.palette.primary.main, overlay = false } = rest;

  const classes = useLocalStyles();

  const SpinnerComponent = () => <Spinner loading={loading} size={size} color={color} />;

  return !overlay ? (
    <>
      <div hidden={!loading} className={classes.centered}>
        <SpinnerComponent />
      </div>
      <div hidden={loading}>{children}</div>
    </>
  ) : (
    <LoadingOverlay active={loading} spinner={<SpinnerComponent />} text={overlayText}>
      {children}
    </LoadingOverlay>
  );
};
export default Loader;
