import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { CardHeader } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    maxWidth: 345,
  },
  header: {
    textAlign: 'center',
    marginBottom: '-2rem',
  },
});

const Prizecard = ({ prize }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant='outlined'>
      <CardHeader
        className={classes.header}
        title={
          <Typography variant='h5' component='h5'>
            {prize?.title}
          </Typography>
        }
        subheader={`${prize?.prizeCategory}`}
      />
      <CardContent>
        <Typography variant='body2' component='p'>
          {prize?.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Prizecard;
