import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { useAuth } from '../../hooks/useAuth';
import { useForm } from 'react-hook-form';
import { TextField } from '@material-ui/core';

const MarkPaidDialog = ({ isOpen, handleClose, tickets, handleClaimPayment }) => {
  const { user } = useAuth();
  const [userRequestedTickets, setUserRequestedTickets] = useState([]);
  const { handleSubmit, register, errors } = useForm();

  useEffect(() => {
    if (tickets) {
      tickets.forEach((ticket) => {
        if (ticket.requester === user?.username) {
          setUserRequestedTickets((requestedTickets) => [...requestedTickets, ticket.number]);
        }
      });
    }
  }, [tickets, user]);

  const handleMarkAllPaid = (formvals) => {
    handleClaimPayment(formvals);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='paid-dialog-title'
      aria-describedby='paid-dialog-description'>
      <DialogTitle id='paid-dialog-title'>Mark Your Tickets Paid?</DialogTitle>
      <form onSubmit={handleSubmit(handleMarkAllPaid)}>
        <DialogContent>
          <DialogContentText id='paid-dialog-description'>{`You have requested ${userRequestedTickets.length} tickets. If they have all been paid for, let the raffler know by filling out the form and clicking "MARK PAID".`}</DialogContentText>

          <TextField
            inputRef={register({
              pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              required: 'Email used for payment is required',
            })}
            type='email'
            variant='outlined'
            margin='normal'
            fullWidth
            id='email'
            label='Email used for payment*'
            name='email'
            error={!!errors.email}
            helperText={
              (errors.email && errors.email?.message) || (errors.email?.type === 'pattern' && 'Invalid email')
            }
          />

          <TextField
            inputRef={register({
              required: 'Name used for payment is required',
            })}
            type='text'
            variant='outlined'
            margin='normal'
            fullWidth
            id='name'
            label='Name used for payment*'
            name='name'
            error={!!errors.name}
            helperText={errors.name && errors.name?.message}
          />

          <TextField
            inputRef={register()}
            type='text'
            multiline
            rows={3}
            variant='outlined'
            margin='normal'
            fullWidth
            id='note'
            label='Optional note for the raffler'
            name='note'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            Cancel
          </Button>
          <Button type='submit' color='primary'>
            Mark Paid
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default MarkPaidDialog;
