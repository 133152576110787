import React from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import useHttpClient from '../../hooks/useHttpClient';
import RaffleForm from '../RaffleForm';

const EditRaffle = () => {
  const { raffleId } = useParams();

  const { data: raffle, error: getRaffleError, loading: getRaffleLoading } = useHttpClient({
    url: `/raffle-service/raffles/${raffleId}`,
    initialRequest: true,
  });

  return (
    <Loader loading={getRaffleLoading}>
      <Alert show={getRaffleError} severity='error'>
        <b>{`Error retrieving raffle: ${getRaffleError}`}</b>
      </Alert>
      {raffle && <RaffleForm raffle={raffle} />}
    </Loader>
  );
};

export default EditRaffle;
