import React, { useState, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getEnvVariable } from '../utils/envUtils';
import auth0 from 'auth0-js';
import jwt_decode from 'jwt-decode';
import { withRouter } from 'react-router-dom';
import Loader from '../components/loader';
import { useMountEffect } from '../hooks/useMountEffect';
import ConfirmationDialog from '../components/confirmationDialog';
import { ROLES } from '../utils/constants';

const webAuth = new auth0.WebAuth({
  domain: getEnvVariable('auth0Domain'),
  clientID: getEnvVariable('auth0ClientId'),
  redirectUri: getEnvVariable('auth0RedirectUri'),
  audience: 'https://api.zapraffle.com/raffle-api',
  scope: 'openid profile email',
});

const loginRealm = 'Username-Password-Authentication';
const responseType = 'id_token token';

const logout = () => {
  webAuth.logout({ clientID: getEnvVariable('auth0ClientId'), returnTo: getEnvVariable('baseUrl') });
};

const AuthProvider = ({ children, history, location }) => {
  const { pathname, hash, search } = location;
  const [authContext, setAuthContext] = useState({ loggedIn: false, user: null });
  const [loading, setLoading] = useState(false);
  const [isExpiredDialogOpen, setIsExpiredDialogOpen] = useState(false);

  const checkSession = useCallback(() => {
    const decodedAccessToken = authContext?.user?.accessToken && jwt_decode(authContext?.user?.accessToken);

    if (Date.now() >= decodedAccessToken?.exp * 1000) {
      setIsExpiredDialogOpen(true);
      return false;
    } else {
      return true;
    }
  }, [authContext]);

  const refreshSession = () => {
    webAuth.checkSession({ responseType }, (err, resp) => handleParseHashResponse(err, resp));
  };

  const handleExpiredDialogClose = () => {
    setIsExpiredDialogOpen(false);
    refreshSession();
  };

  const handleParseHashResponse = useCallback(
    (err, authResult, redirect) => {
      if (err) {
        // comes here on page refresh if user not logged in
        if (err.errorDescription === 'Please verify your email before logging in.') {
          logout();
          history.push('/verify-email');
        }
        return;
      }

      const decodedAccessToken = jwt_decode(authResult.accessToken);

      //auto refresh the session 60 seconds before it expires
      setTimeout(function () {
        webAuth.checkSession({ responseType }, (err, resp) => handleParseHashResponse(err, resp));
      }, (decodedAccessToken.exp - decodedAccessToken.iat - 60) * 1000);

      const userRoles = authResult.idTokenPayload['https://zapraffle.com/role'];

      setAuthContext({
        ...authContext,
        loggedIn: true,
        user: {
          roles: userRoles,
          accessToken: authResult.accessToken,
          idToken: authResult.idToken,
          id: authResult.idTokenPayload.sub,
          username: authResult.idTokenPayload['https://zapraffle.com/username'],
          email: authResult.idTokenPayload.email,
          emailVerified: authResult.idTokenPayload.email_verified,
          canCreateRaffles: [ROLES.ADMIN, ROLES.RAFFLE_CREATOR].some((role) => userRoles?.includes(role)),
        },
      });

      if (redirect) {
        history.push({
          pathname: redirect,
        });
      }
    },
    [authContext, history],
  );

  const handleAuthCallback = useCallback(() => {
    webAuth.parseHash({ hash }, (err, resp) => handleParseHashResponse(err, resp));
  }, [handleParseHashResponse, hash]);

  function registerUser(regObj) {
    return new Promise((resolve, reject) => {
      webAuth.signup(
        {
          connection: 'Username-Password-Authentication',
          email: regObj.email,
          username: regObj.username,
          password: regObj.password,
          user_metadata: { caseSensitiveUsername: regObj.username },
        },
        function (error, result) {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        },
      );
    });
  }
  function resetPassword(regObj) {
    return new Promise((resolve, reject) => {
      webAuth.changePassword(
        {
          connection: 'Username-Password-Authentication',
          email: regObj.email,
        },
        function (error, result) {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        },
      );
    });
  }

  useMountEffect(() => {
    const checkAuth = () => {
      try {
        setLoading(true);
        // callback happens after form login
        if (pathname === '/callback' && !!hash) {
          handleAuthCallback();
        } else if (!authContext.loggedIn) {
          //checks if user is still logged in without form login (e.g. on refresh of page)
          webAuth.checkSession({ responseType }, (err, resp) => handleParseHashResponse(err, resp));
        }
      } catch (e) {
        //check session will throw this error and that is fine
        if (e?.code !== 'login_required') {
          console.error('error checking user auth', e);
        }
      } finally {
        setLoading(false);
      }
    };
    checkAuth();
  });

  const authContextValue = {
    ...authContext,
    setAuthContext,
    registerUser,
    resetPassword,
    login: (loginOpts, errorCallback) => {
      webAuth.login(
        { loginRealm, responseType, redirectUri: `${getEnvVariable('auth0RedirectUri')}${search}`, ...loginOpts },
        errorCallback,
      );
    },
    socialLogin: (connection, errorCallback) => {
      webAuth.authorize({ responseType, connection }, errorCallback);
    },
    logout,
    checkSession,
    refreshSession,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <Loader loading={loading}>{children}</Loader>
      <ConfirmationDialog
        body='Your session expired, attempting refresh.'
        handleClose={handleExpiredDialogClose}
        handleConfirm={handleExpiredDialogClose}
        hideCancel={true}
        isOpen={isExpiredDialogOpen}
        okText='OK'
        title='Session Expired!'
      />
    </AuthContext.Provider>
  );
};

export default withRouter(AuthProvider);
