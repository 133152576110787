import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { convertUtcStringToFriendlyCurrentTimezone } from '../../utils/dataUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    maxWidth: 345,
    maxHeight: 285,
    paddingBottom: '1rem',
  },
  priceRoot: {
    marginBottom: '-2rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  media: {
    height: 0,
    maxHeight: '100%',
    paddingTop: '56.25%', // 16:9
  },
  header: {
    extAlign: 'left',
    fontSize: '1rem',
    paddingBottom: 0,
  },
}));

export default function RaffleCard({ raffle }) {
  const { mainImage, title, startTime, ticketPrice, id } = raffle;
  const classes = useStyles();

  const [raised, setRaised] = useState(false);

  const toggleRaised = () => {
    setRaised(!raised);
  };

  const SubHeader = ({ startTime }) => {
    const past =
      startTime <
      new Date().toLocaleString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });

    return past ? <div>Ended {startTime}</div> : <div>Starts {startTime}</div>;
  };

  return (
    <Card className={classes.root} onMouseOver={toggleRaised} onMouseOut={toggleRaised} raised={raised}>
      <Link style={{ textDecoration: 'none', color: 'inherit' }} to={`/raffle/${id}`}>
        <CardHeader
          className={classes.header}
          title={<Typography component='h5'>{title}</Typography>}
          subheader={<SubHeader startTime={convertUtcStringToFriendlyCurrentTimezone(startTime)} />}
        />
        <CardContent style={{ paddingTop: 0 }}>
          <Typography variant='body2' component='p'>
            {raffle?.ticketsRemaining > 0
              ? `${raffle?.ticketsRemaining} tickets remaining at $${ticketPrice.toLocaleString()}`
              : 'Raffle Full'}
          </Typography>
        </CardContent>
        <CardMedia className={classes.media} image={mainImage} title={title} />
      </Link>
    </Card>
  );
}
