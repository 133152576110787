export const ENVIRONMENT_VARIABLES = {
  apiGateway: {
    // use raffle-app-gateway 8082 when not using chat (gateway doesnt work with chat)
    // otherwise have to use comm sevice 8081 for chat dev
    local: 'http://localhost:8080',
    //local: 'https://api.staging.zapraffle.com',
    staging: 'https://api.staging.zapraffle.com',
    prod: 'https://api.zapraffle.com',
  },
  auth0Domain: {
    // local: 'auth.staging.zapraffle.com',
    local: 'np-zapraffle.eu.auth0.com',
    staging: 'np-zapraffle.eu.auth0.com',
    prod: 'auth.zapraffle.com',
  },
  auth0ClientId: {
    local: 'KdZzzfDsW8RiCFmQlgpRad5Z8hsWXvxT',
    staging: 'KdZzzfDsW8RiCFmQlgpRad5Z8hsWXvxT',
    prod: 'eZ81M7SEk218Uv5FMFKWKXuPaJdUcxXi',
  },
  auth0RedirectUri: {
    //local: 'http://0.0.0.0:3000/callback',
    local: 'http://localhost:3000/callback',
    staging: 'https://staging.zapraffle.com/callback',
    prod: 'https://zapraffle.com/callback',
  },
  baseUrl: {
    local: 'http://localhost:3000',
    staging: 'https://staging.zapraffle.com',
    prod: 'https://zapraffle.com',
  },
  imgurClientId: {
    local: 'c09aa400ec124f3', // local api calls dont work when referer is localhost
    staging: 'c09aa400ec124f3',
    prod: '733e95322db6c9d',
  },
};

export const ENVIRONMENTS = {
  LOCAL: 'LOCAL',
  STAGING: 'STAGING',
  PROD: 'PROD',
};

export const HTTP_ACTION = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const REGEX_PATTERN = {
  REG_USERNAME: /^([0-9a-zA-Z@^$.!`\-#+'~_]){3,20}$/,

  IMGUR_LINK: /^https?:\/\/imgur.com\/a\/(.+)\/?$/i,
};

export const ROLES = {
  ADMIN: 'admin',
  RAFFLE_CREATOR: 'raffle_creator',
};

export const NOTIFICATIONS = {
  PRIZE_WINNER: 'PRIZE_WINNER',
  RAFFLE_COMPLETE: 'RAFFLE_COMPLETE',
};
