import { Paper, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import React from 'react';
import { useParams } from 'react-router-dom';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import TicketList from '../../components/ticketList';
import useHttpClient from '../../hooks/useHttpClient';
import { useStyles } from '../../hooks/useStyles';

const ManageTickets = () => {
  const globalStyles = useStyles();
  const { raffleId } = useParams();

  const { data: raffle, error: getRaffleError, loading: getRaffleLoading } = useHttpClient({
    url: `/raffle-service/raffles/${raffleId}`,
    initialRequest: true,
  });

  return (
    <Container component='main' maxWidth='lg'>
      <Loader loading={getRaffleLoading}>
        <Alert show={getRaffleError} severity='error'>
          <b>{`Error retrieving raffle: ${getRaffleError}`}</b>
        </Alert>
        <Paper className={globalStyles.paper}>
          <Typography component='h1' variant='h5' style={{ margin: '1rem 0rem' }}>
            {`Updating tickets for: "${raffle?.title}"`}
          </Typography>
          <TicketList raffle={raffle} readOnly={false} />
        </Paper>
      </Loader>
    </Container>
  );
};

export default ManageTickets;
