import { Box, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import React from 'react';
import { useLocation } from 'react-router';
import Faq from '../../components/Faq';

const faqs = [
  {
    id: 'fair-winner',
    title: 'How do I know that winners are chosen in a fair way?',
    description:
      'ZapRaffle uses random.org as a third party to choose a random number corresponding with tickets in raffles. random.org provides a mechanism for anyone to verify the authenticity and integrity of the random numbers it chooses. This FAQ page contains further details on that verification process.',
  },
  {
    id: 'verify-winner',
    title: 'How do I verify winning tickets?',
    description: (
      <>
        ZapRaffle uses random.org as a third party to choose a random number corresponding with tickets in raffles. Find
        the verification info for a raffle by going to the raffle's details page and clicking "VIEW VERIFICATION INFO"
        under the "Winners" section. Here you will see all the information you will need to verify the authenticity and
        integrity of the winning ticket(s).
        <Typography variant='h6' style={{ textAlign: 'center' }}>
          Steps to verify
        </Typography>
        <ol>
          <li>
            Click the "VERIFY WINNER(S)" link to go to random.org's verification page with all the pertinent fields
            filled in. If you prefer to manually fill out the fields you can paste the "Random" and "Signature" values
            into their respective fields <a href='https://api.random.org/verify'> on the random.org verify page</a> and
            after you click "Verify Signature" you will see "Authenticity Check Succeeded" on the page along with other
            details.
          </li>
          <li>Verify min, max, method, n, data, and completionTime are as described below.</li>
          <li>
            Find the verification info for the previous serialNumber (there should not be gaps) and verify it has the
            same hashedApiKey since it should not be changing and it is tied to the serialNumber (a call using a
            different API Key would not increment another ones serialNumber).
          </li>
        </ol>
        <Typography variant='h6' style={{ textAlign: 'center' }}>
          Components of random.org response
        </Typography>
        <ul>
          <li>
            <strong>Random</strong> - a JSON string containing the pertinent info of the call to the random.org API.
          </li>
          <li>
            <strong>Signature</strong> - a signed version of the Random object, created by random.org.
          </li>
          <li>
            <strong>hashedApiKey</strong> - This should remain the same for every API call. The serialNumber is tied to
            this.
          </li>
          <li>
            <strong>min</strong> - This should always be 1 since that is the lowest number ticket that can win.
          </li>
          <li>
            <strong>max</strong> - This should always be the number of slots in the raffle since that is the highest
            slot that can win.
          </li>
          <li>
            <strong>method</strong> - Should always be generateSignedIntegers. That is the method random.org uses to get
            our winner.
          </li>
          <li>
            <strong>n</strong> - This is the number of random numbers chosen.
          </li>
          <li>
            <strong>base</strong> - Should always be 10. Base 10 is how we normally read numbers.
          </li>
          <li>
            <strong>data</strong> - Contains the winning number(s).
          </li>
          <li>
            <strong>completionTime</strong> - The time the API call completed (Zulu timezone).
          </li>
          <li>
            <strong>serialNumber</strong> - Increments by 1 every time the bot makes an API call.
          </li>
        </ul>
      </>
    ),
  },
];

export const FaqPage = () => {
  const { hash } = useLocation();

  return (
    <>
      <Container component='main' maxWidth='md'>
        <Box mt={2}>
          <Typography variant='h5' component='h1'>
            FAQs
          </Typography>
          {faqs.map((faq) => (
            <Faq faq={faq} initiallyExpanded={faq.id === hash?.substring(1)} key={faq.id} />
          ))}
        </Box>
      </Container>
    </>
  );
};

export default FaqPage;
