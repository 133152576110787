import React from 'react';
import ConfirmationDialog from '../confirmationDialog';

const RemoveUnpaidDialog = ({
  isOpen,
  handleClose,
  handleRemoveUnpaid,
  hasUnverifiedPaymentClaims,
  hasUnpaidTickets,
}) => {
  return (
    <>
      <ConfirmationDialog
        isOpen={isOpen && hasUnpaidTickets}
        handleClose={handleClose}
        handleConfirm={handleRemoveUnpaid}
        title='Remove Unpaid?'
        body={`${
          hasUnverifiedPaymentClaims ? 'WARNING! You still have unverified payment claims to check. ' : ''
        }Are you sure you would like to remove all unpaid users?`}
        okText='REMOVE UNPAID'
      />

      <ConfirmationDialog
        isOpen={isOpen && !hasUnpaidTickets}
        handleClose={handleClose}
        handleConfirm={handleClose}
        title='No unpaid users to remove.'
        body={'There are no unpaid users to remove at this time.'}
        hideCancel={true}
        okText='OK'
      />
    </>
  );
};

export default RemoveUnpaidDialog;
