import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function SetupRaffleAccountCard() {
  const classes = useStyles();

  return (
    <Grid container style={{ textAlign: 'center' }}>
      <Grid item xs={6}>
        <Link to={'/raffle'}>
          <Card className={classes.root} xs={4}>
            <CardContent>
              <Typography variant='h6' component='h3'>
                Create a raffle
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </Grid>
    </Grid>
  );
}
