import { useEffect } from 'react';

export const useOutsideClickTracker = (ref, cb, ignoreRefs, ignoreOutsideClick) => {
  useEffect(() => {
    function handleClickOutside(event) {
      // dont fire if click is in ignored ref
      if (
        !ignoreOutsideClick &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !ignoreRefs?.some((r) => r.current?.contains(event.target))
      ) {
        if (cb) {
          cb();
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [cb, ignoreOutsideClick, ignoreRefs, ref]);
};
