import { Box, Container, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import Alert from '../../components/alert';
import { RaffleCard } from '../../components/cards';
import Loader from '../../components/loader';
import useHttpClient from '../../hooks/useHttpClient';
import InfiniteScroll from 'react-infinite-scroller';

const HomePage = () => {
  const [raffles, setRaffles] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const [fetchingNext, setFetchingNext] = useState(false);
  const pageSize = 15;

  const { httpRequest: getRaffles, data: latestRaffles, error, loading } = useHttpClient();

  const getNextRafflePage = useCallback(
    async (page) => {
      setFetchingNext(true);
      try {
        const raffleData = (
          await getRaffles({
            url: `/raffle-service/raffles?page=${page - 1}&pageSize=${pageSize}&draft=false&summary=true`,
          })
        )?.response?.data;
        if (raffleData) {
          setRaffles((prev) => [...prev, ...raffleData]);
        }
      } finally {
        setFetchingNext(false);
      }
    },
    [getRaffles],
  );

  useEffect(() => {
    // TODO: Parse URL Params to show messages from url params
    const params = window.location.href.split('message=');
    if (params[1] === 'raffle-saved-for-later') {
      setAlertMessage('Raffle has been saved for later');
    }
    if (params[1] === 'raffle-created') {
      setAlertMessage('Raffle has been created!');
    }
  }, []);

  return (
    <>
      <Container maxWidth={'lg'} style={{ marginBottom: '2rem', marginTop: '1rem', margin: 'auto' }}>
        <Box mt={2}>
          <Alert show={error} severity={'error'}>
            {`Error getting raffles: ${error}`}
          </Alert>
          <Alert show={alertMessage} severity={'info'}>
            <b>{alertMessage}</b>
          </Alert>
          <div style={{ margin: '1rem 0' }}>
            <h1 className={'header1'}>Upcoming raffles</h1>
          </div>

          <InfiniteScroll
            loadMore={getNextRafflePage}
            hasMore={!fetchingNext && !error && (latestRaffles == null || latestRaffles?.length > 0)}>
            <Grid container spacing={2} direction='row' justify='flex-start' alignItems='flex-start'>
              {raffles?.map((raffle) => (
                <Grid item xs={12} sm={6} md={3} key={raffle.id}>
                  <RaffleCard raffle={raffle} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
          <Loader loading={(!error && (latestRaffles == null || latestRaffles?.length > 0)) || loading} />
        </Box>
      </Container>
    </>
  );
};

export default HomePage;
