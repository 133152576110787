import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Alert from '../../components/alert';
import useHttpClient from '../../hooks/useHttpClient';
import { HTTP_ACTION } from '../../utils/constants';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const DeleteRaffleModal = ({ raffle }) => {
  const classes = useStyles();
  const history = useHistory();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const { handleSubmit, register, errors } = useForm({ shouldFocusError: true, mode: 'blur' });
  const { httpRequest: deleteRaffle, error: deleteError } = useHttpClient({
    httpAction: HTTP_ACTION.DELETE,
    url: `/raffle-service/raffles/${raffle.id}`,
  });

  const { httpRequest: cancelRaffle, error: cancelError } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: `/raffle-service/raffles/${raffle.id}/cancel`,
  });

  const HandleClose = () => {
    setOpen(false);
  };
  const HandleOpen = () => {
    setOpen(true);
  };

  const handleConfirm = async () => {
    const response = (raffle.draft ? await deleteRaffle() : await cancelRaffle()).response;

    if (Math.floor(response?.status / 100) === 2) {
      history.push(`/raffles/manage?status=raffle-${raffle.draft ? 'deleted' : 'canceled'}&id=${raffle.id}`);
    }
  };

  const validateInput = (value) => {
    if (raffle.title !== value) {
      return 'Raffle name does not match input';
    }
  };

  return (
    <>
      <Button disabled={raffle.canceled} variant={'text'} size={'small'} onClick={HandleOpen}>
        {raffle.draft ? 'Delete' : 'Cancel'}
      </Button>
      <Modal open={open} onClose={HandleClose}>
        <div style={modalStyle} className={classes.paper}>
          <Alert show={deleteError || cancelError} severity={'error'}>
            {deleteError || cancelError}
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Are you absolutely sure?</h3>
            </Grid>
            <Grid item xs={12}>
              <p>
                This action cannot be undone.{' '}
                {raffle.draft
                  ? 'This will permanently delete the raffle and remove all info related to it.'
                  : 'This will permanently cancel the raffle. You must refund all paid participants after cancelling.'}
              </p>
              <p>
                Please type <b>{raffle.title}</b> to confirm
              </p>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(handleConfirm)}>
                <TextField
                  defaultValue={raffle?.raffleName}
                  error={errors.raffleName}
                  helperText={errors.raffleName && errors.raffleName?.message}
                  fullWidth
                  id='raffleName'
                  inputRef={register({
                    required: 'Raffle name is required',
                    validate: (value) => validateInput(value),
                  })}
                  name='raffleName'
                  type={'text'}
                  variant='outlined'
                />
                <Grid item sm={12}>
                  <Button type={'submit'} variant={'outlined'} style={{ width: '100%', margin: '1rem 0' }}>
                    {raffle.draft ? 'Delete' : 'Cancel Raffle'}
                  </Button>
                </Grid>
                <Grid item sm={12}>
                  <Button onClick={HandleClose} style={{ width: '100%' }}>
                    Abort
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default DeleteRaffleModal;
