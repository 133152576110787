import React, { useEffect, useState } from 'react';
import { useChat } from '../../hooks/useChat';
import { Badge, Box, FormControl, MenuItem, Select } from '@material-ui/core';

const ConversationList = ({ conversations = [], openCloseCallback }) => {
  const { activeChat, updateActiveChat, markConversationRead } = useChat();
  const [selectedConversation, setSelectedConversation] = useState({});

  useEffect(() => {
    const activeConversation = conversations.find((conversation) => conversation.withUser === activeChat?.withUser);
    if (activeChat?.withUser && activeConversation?.numUnread > 0) {
      markConversationRead(activeChat?.withUser);
    }
  }, [activeChat, conversations, markConversationRead]);

  useEffect(() => {
    setSelectedConversation(conversations.find((conversation) => conversation.withUser === activeChat?.withUser));
  }, [activeChat, conversations]);

  return (
    <Box padding='10px'>
      <FormControl variant='outlined' fullWidth>
        <Select
          id='conversation-select'
          value={selectedConversation || {}}
          onChange={(event) => {
            updateActiveChat(event.target.value);
          }}
          onOpen={() => openCloseCallback(true)}
          onClose={() => openCloseCallback(false)}>
          {conversations.map((conversation) => (
            <MenuItem key={conversation.withUser} value={conversation}>
              <Box>
                <Badge badgeContent={conversation?.numUnread || 0} color='primary'>
                  {conversation.withUser}
                </Badge>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ConversationList;
