import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { REGEX_PATTERN } from '../../utils/constants';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';

const useLocalStyles = makeStyles((theme) => ({
  disabledCheckbox: {
    '&.Mui-disabled': {
      color: theme.palette.primary.main,
    },
    '&.Mui-disabled + span.Mui-disabled': {
      color: 'black',
    },
  },
}));

const TicketFields = ({ initialTicket, handleUpdateTicket, readOnly = true }) => {
  const classes = useLocalStyles();
  const [ticket, setTicket] = useState({ ...initialTicket });

  const { handleSubmit, register, errors, reset, control } = useForm({
    defaultValues: {
      owner: ticket.owner,
      paid: ticket.paid === true,
    },
  });
  const [showEditButtons, setShowEditButtons] = useState(false);

  const handleChange = () => {
    setShowEditButtons(true);
  };

  const hideEditButtons = () => {
    setShowEditButtons(false);
  };

  const handleSetTicket = (updatedTicket) => {
    setTicket(updatedTicket);
    reset({ owner: updatedTicket.owner, paid: updatedTicket.paid });
  };

  const handleCancel = () => {
    reset({ owner: ticket.owner, paid: ticket.paid });
    hideEditButtons();
  };

  const handleSubmitTicket = (formVals) => {
    handleUpdateTicket(formVals, ticket, handleSetTicket);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitTicket)}>
      <FormGroup row key={ticket.number}>
        <TextField
          inputRef={register({
            pattern: { value: REGEX_PATTERN.REG_USERNAME, message: 'Invalid username character' },
            maxLength: { value: 20, message: 'Max length is 20 characters' },
            minLength: { value: 3, message: 'min length is 3 characters' },
          })}
          variant='outlined'
          margin='normal'
          id='owner'
          label='Ticket Owner'
          name='owner'
          error={!!errors.owner}
          helperText={errors.owner && errors.owner?.message}
          InputProps={{
            readOnly: readOnly,
            startAdornment: <InputAdornment position='start'>{`${ticket.number}:`}</InputAdornment>,
          }}
          size='small'
          onChange={handleChange}
        />

        <Controller
          control={control}
          name='paid'
          render={({ onChange, onBlur, value, ref }) => (
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleChange();
                  }}
                  checked={value}
                  inputRef={ref}
                  disabled={readOnly}
                  onBlur={onBlur}
                  classes={{
                    disabled: classes.disabledCheckbox,
                  }}
                />
              }
              label='Paid:'
              labelPlacement='start'
            />
          )}
        />

        <Box ml={3} display='flex' visibility={!showEditButtons && 'hidden'}>
          <IconButton type='submit' aria-label='save' size='small' onClick={hideEditButtons}>
            <CheckCircleOutlineIcon style={{ color: green[500] }} />
          </IconButton>
          <IconButton aria-label='save' size='small' onClick={handleCancel}>
            <CancelIcon style={{ color: red[400] }} />
          </IconButton>
        </Box>
      </FormGroup>
    </form>
  );
};

export default TicketFields;
