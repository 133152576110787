import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Box, Divider, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import ReactTimeago from 'react-timeago';
import { convertToFriendlyDate } from '../../utils/dataUtils';
import { NOTIFICATIONS } from '../../utils/constants';
import ConfirmationDialog from '../confirmationDialog';
import { useHistory } from 'react-router';
import { useChat } from '../../hooks/useChat';

const useLocalStyles = makeStyles((theme) => ({
  timeAgo: {
    color: theme.palette.primary.main,
    fontSize: 'small',
    fontWeight: 'bold',
  },
}));

const Notification = ({ notification, closeNotificationMenu }) => {
  const classes = useLocalStyles();

  const { openChat } = useChat();

  const handleNotificationClick = useCallback(
    () => () => {
      handleOpenNotificationDialog();
    },
    [],
  );
  const [clickHandler, setClickHandler] = useState(() => () => {});

  const [isNotificationDialogOpen, setIsNotificationDialogOpen] = useState(false);

  const [dialogBody, setDialogBody] = useState('');
  const [okText, setOkText] = useState('');
  const [cancelText, setCancelText] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');
  const [closeHandler, setCloseHandler] = useState(() => () => {});
  const [confirmHandler, setConfirmHandler] = useState(() => () => {});
  const [additionalActions, setAdditionalActions] = useState([]);

  const history = useHistory();

  const handleGoToRaffle = useCallback(
    () => () => {
      if (notification.metadata?.raffleId) {
        closeNotificationMenu();
        history.push(`/raffle/${notification.metadata?.raffleId}`);
      }
    },
    [closeNotificationMenu, history, notification],
  );
  const handleOpenNotificationDialog = () => {
    setIsNotificationDialogOpen(true);
  };
  const handleClosePrizeWinnerDialog = useCallback(() => {
    setIsNotificationDialogOpen(false);
  }, []);
  const handleConfirmPrizeWinnerDialog = useCallback(() => {
    handleClosePrizeWinnerDialog();
    closeNotificationMenu();
    history.push(`/raffle/${notification.metadata?.raffleId}`);
  }, [closeNotificationMenu, handleClosePrizeWinnerDialog, history, notification]);

  const startChat = useCallback(
    (userName) => {
      openChat(userName);
      handleClosePrizeWinnerDialog();
      closeNotificationMenu();
    },
    [closeNotificationMenu, handleClosePrizeWinnerDialog, openChat],
  );

  useEffect(() => {
    switch (notification.type) {
      case NOTIFICATIONS.PRIZE_WINNER:
        const raffler = notification.metadata?.raffler;
        const prizeTitles = notification.metadata?.prizes.map((prize) => prize.title);

        setClickHandler(handleNotificationClick);
        setDialogBody(
          `Congrats! Contact ${raffler} to give them your shipping info. You have won the following prize(s): 
          ${prizeTitles.join(',')}
          `,
        );
        setOkText('Go To Raffle');
        setCancelText('Close');
        setDialogTitle("You're a Winner!");
        setConfirmHandler(() => handleConfirmPrizeWinnerDialog);
        setCloseHandler(() => handleClosePrizeWinnerDialog);
        setAdditionalActions([{ text: 'Chat with Raffler', onClick: () => startChat(raffler) }]);
        break;
      default:
        setClickHandler(handleGoToRaffle);
        break;
    }
  }, [
    handleClosePrizeWinnerDialog,
    handleConfirmPrizeWinnerDialog,
    handleGoToRaffle,
    handleNotificationClick,
    notification,
    startChat,
  ]);

  return (
    <>
      <ListItem button onClick={clickHandler}>
        <Box display='flex' flexDirection='column'>
          <Badge
            invisible={notification.seenByUser}
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            color='error'
            variant='dot'>
            <ListItemText primary={notification.summary} />
          </Badge>
          <ReactTimeago
            className={classes.timeAgo}
            date={notification.createdDatetime}
            title={convertToFriendlyDate(notification.createdDatetime)}
          />
        </Box>
      </ListItem>
      <Divider />

      <ConfirmationDialog
        body={dialogBody}
        handleClose={closeHandler}
        handleConfirm={confirmHandler}
        isOpen={isNotificationDialogOpen}
        okText={okText}
        cancelText={cancelText}
        title={dialogTitle}
        additionalActions={additionalActions}
      />
    </>
  );
};

export default Notification;
