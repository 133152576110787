import { Box, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import React, { useCallback, useState } from 'react';
import useHttpClient from '../../hooks/useHttpClient';
import { useStyles } from '../../hooks/useStyles';
import WinnerVerificationDisplay from '../../components/winnerVerificationDisplay';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import InfiniteScroll from 'react-infinite-scroller';

export const WinnerVerificationPage = () => {
  const styles = useStyles();
  const [raffles, setRaffles] = useState([]);
  const [fetchingNext, setFetchingNext] = useState(false);
  const pageSize = 25;

  const { httpRequest: getRaffles, data: latestRaffles, error, loading } = useHttpClient();

  const getNextRafflePage = useCallback(
    async (page) => {
      setFetchingNext(true);
      try {
        const raffleData = (
          await getRaffles({
            url: `/raffle-service/raffles?page=${page - 1}&pageSize=${pageSize}&complete=true&summary=true`,
          })
        )?.response?.data;
        if (raffleData) {
          setRaffles((prev) => [...prev, ...raffleData]);
        }
      } finally {
        setFetchingNext(false);
      }
    },
    [getRaffles],
  );

  return (
    <>
      <Container component='main' maxWidth='md'>
        <Alert show={error} severity={'error'}>
          {`Error getting verification info: ${error}`}
        </Alert>
        <Box mt={2}>
          <InfiniteScroll
            loadMore={getNextRafflePage}
            hasMore={!fetchingNext && !error && (latestRaffles == null || latestRaffles?.length > 0)}>
            <Typography variant='h5' component='h1'>
              Winner Verification
            </Typography>
            <Paper elevation={5} className={`${styles.paper} `}>
              <WinnerVerificationDisplay completeRaffles={raffles} />
            </Paper>
          </InfiniteScroll>
          <Loader loading={(!error && (latestRaffles == null || latestRaffles?.length > 0)) || loading} />
        </Box>
      </Container>
    </>
  );
};

export default WinnerVerificationPage;
