import { Box, Button, Grid, IconButton, makeStyles, Slider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import { HTTP_ACTION } from '../../utils/constants';
import useHttpClient from '../../hooks/useHttpClient';
import Loader from '../loader';
import Alert from '../alert';
import { PulseLoader } from 'react-spinners';

const avatarEditorWidthPx = 300;

const useStyles = makeStyles((theme) => ({
  sliderRoot: {
    display: 'flex',
    marginTop: theme.spacing(1),
    width: '85%',
    maxWidth: `${avatarEditorWidthPx}px`,
    justifyContent: 'space-between',
  },
  slider: {
    display: 'flex',
    width: '67%',
  },
}));

const ImageEditor = ({ onImageSave, setImageUploading, initialValue }) => {
  const classes = useStyles();
  const [rawFile, setRawFile] = useState();
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [editor, setEditor] = useState();
  const [error, setError] = useState();

  const { httpRequest: uploadFileRequest, error: uploadError, loading } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: '/raffle-service/files',
  });

  const disableControls = !rawFile && !initialValue;

  useEffect(() => {
    if (setImageUploading) {
      setImageUploading(loading);
    }
  }, [loading, setImageUploading]);

  const uploadImage = async (addedFile) => {
    var formData = new FormData();
    formData.append('file', addedFile);
    const uploadResponse = (await uploadFileRequest({ requestBody: formData })).response;

    if (uploadResponse) {
      return { file: addedFile, uploadedFileName: uploadResponse.data.fileName };
    } else {
      return undefined;
    }
  };

  const uploadFile = async (file) => {
    const uploadedImage = await uploadImage(file);
    if (onImageSave && uploadedImage) {
      onImageSave(uploadedImage.uploadedFileName);
    }
  };

  const handleSelectFile = async (e) => {
    if (e.target?.files[0]) {
      if (e.target?.files[0].size > 1024 * 1024 * 10) {
        setError('File too big. Must be less than 10MB');
        return;
      }

      setRawFile(e.target?.files[0]);
      uploadFile(e.target?.files[0]);
    }
  };

  const handleZoomChange = (e, newZoom) => {
    setZoom(newZoom);
  };

  const handleRotateLeft = () => {
    setRotate(rotate - 90);
  };
  const handleRotateRight = () => {
    setRotate(rotate + 90);
  };

  const handleSave = () => {
    if (editor) {
      const canvasScaled = editor.getImage();
      canvasScaled.toBlob(async (blob) => {
        const editedFile = new File([blob], 'editedFile');
        uploadFile(editedFile);
      });
    }
  };

  return (
    <>
      <Alert show={uploadError} severity='error'>
        Error uploading images. Remove the images below and try again: {error && error.message ? error.message : error}
      </Alert>
      <Alert show={error} severity='error'>
        Error uploading images: {error}
      </Alert>
      <Loader loading={loading} Spinner={PulseLoader} size={15} overlay={true} overlayText='Uploading Images...'>
        <Grid container direction='column' alignItems='center' justify='center'>
          <Grid item xs={12}>
            <AvatarEditor
              ref={setEditor}
              crossOrigin='anonymous'
              image={rawFile || initialValue}
              width={avatarEditorWidthPx}
              height={avatarEditorWidthPx * (9 / 16)}
              scale={zoom}
              rotate={rotate}
            />
          </Grid>

          <Grid item xs={12}>
            <Box component='span' mr={2}>
              <Button variant='outlined' component='label' color='primary'>
                Select Main Album Image
                <input type='file' style={{ display: 'none' }} onChange={handleSelectFile} accept='image/*' />
              </Button>
            </Box>
            <Button
              variant='outlined'
              component='label'
              color='primary'
              onClick={handleSave}
              disabled={disableControls}>
              Save File
            </Button>
          </Grid>
          <Box className={classes.sliderRoot}>
            <Box className={classes.slider}>
              <ZoomOutIcon />
              <Slider
                disabled={disableControls}
                value={zoom}
                onChange={handleZoomChange}
                max={3}
                step={0.01}
                defaultValue={1}
                aria-labelledby='zoom'
              />
              <ZoomInIcon />
            </Box>
            <Box display='flex'>
              <IconButton disabled={disableControls} aria-label='delete' onClick={handleRotateRight} size='small'>
                <RotateRightIcon />
              </IconButton>
              <IconButton disabled={disableControls} aria-label='delete' onClick={handleRotateLeft} size='small'>
                <RotateLeftIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Loader>
    </>
  );
};

export default ImageEditor;
