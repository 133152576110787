import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const ConfirmationDialog = ({
  isOpen,
  title,
  body,
  ClickComponent,
  handleConfirm = () => {},
  handleClose = () => {},
  hideCancel = false,
  cancelText = 'Cancel',
  okText = 'OK',
  additionalActions = [],
}) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogIsOpen(true);
  };
  const handleCloseDialog = () => {
    setDialogIsOpen(false);
    handleClose();
  };
  const handleConfirmDialog = () => {
    setDialogIsOpen(false);
    handleConfirm();
  };

  return (
    <>
      {ClickComponent !== undefined && React.cloneElement(ClickComponent, { onClick: handleOpenDialog })}
      <Dialog
        open={isOpen || dialogIsOpen}
        onClose={handleCloseDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <span dangerouslySetInnerHTML={{ __html: body?.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!hideCancel && (
            <Button onClick={handleCloseDialog} color='primary'>
              {cancelText}
            </Button>
          )}
          <Button onClick={handleConfirmDialog} color='primary' autoFocus>
            {okText}
          </Button>
          {additionalActions?.map((action) => (
            <Button key={action.text} onClick={action.onClick} color='primary' autoFocus>
              {action.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
