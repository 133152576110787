import React, { useState, useEffect, useCallback } from 'react';
import { SidebarContext } from '../context/SidebarContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core';

const defaultSidebarWidth = '8.25rem';

const SidebarProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [width, setWidth] = useState(defaultSidebarWidth);
  const theme = useTheme();
  const smUpMatches = useMediaQuery(theme.breakpoints.up('sm'));

  const openSidebar = useCallback(() => {
    setSidebarOpen(true);
  }, []);
  const closeSidebar = useCallback(() => {
    setSidebarOpen(false);
  }, []);
  const toggleSidebar = useCallback(() => {
    setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen);
  }, []);

  useEffect(() => {
    if (smUpMatches) {
      setWidth(defaultSidebarWidth);
    } else {
      setWidth('0');
    }
  }, [smUpMatches]);

  const sidebarContextValue = {
    width,
    sidebarOpen,
    toggleSidebar,
    openSidebar,
    closeSidebar,
  };
  return <SidebarContext.Provider value={sidebarContextValue}>{children}</SidebarContext.Provider>;
};

export default SidebarProvider;
