import React from 'react';
import { Alert as AlertComponent } from '@material-ui/lab';
import { useStyles } from '../../hooks/useStyles';

const Alert = ({ show, topMargin = 2, bottomMargin = 0, children, ...rest }) => {
  const styles = useStyles();
  return show ? (
    <AlertComponent className={[styles[`mt-${topMargin}`], styles[`mb-${bottomMargin}`]].join(' ')} {...rest}>
      {children}
    </AlertComponent>
  ) : (
    <></>
  );
};

export default Alert;
