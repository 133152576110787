import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { Badge, Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

const PaymentVerifierDialog = ({
  isOpen,
  handleClose,
  handleNext,
  paymentClaim,
  tickets,
  handleVefifyAllPaymentClaims,
  handleVefifyPaymentClaim,
  requestorsPaymentClaims,
}) => {
  const [numPaid, setNumPaid] = useState(0);
  const [numUnpaid, setNumUnpaid] = useState(0);
  const [totalClaimed, setTotalClaimed] = useState(0);
  const [ticketMap, setTicketMap] = useState({});

  useEffect(() => {
    if (paymentClaim && tickets) {
      const requestedTicketMap = {};
      let numPaidTickets = 0;
      let numUnpaidTickets = 0;

      tickets.forEach((ticket) => {
        if (paymentClaim.createdBy === ticket.requester) {
          const ownerTickets = requestedTicketMap[ticket.owner] || { paid: [], unpaid: [] };
          if (ticket.paid) {
            numPaidTickets++;
            ownerTickets.paid.push(ticket);
          } else {
            numUnpaidTickets++;
            ownerTickets.unpaid.push(ticket);
          }
          requestedTicketMap[ticket.owner] = ownerTickets;
        }
      });

      const numClaimed = requestorsPaymentClaims?.reduce((amount, claim) => claim?.ticketNumbers?.length + amount, 0);

      setNumPaid(numPaidTickets);
      setNumUnpaid(numUnpaidTickets);
      setTicketMap(requestedTicketMap);
      setTotalClaimed(numClaimed);
    }
  }, [tickets, paymentClaim, requestorsPaymentClaims]);

  const handleMarkAllPaid = () => {
    handleVefifyAllPaymentClaims();
  };

  const handleMarkSpecifiedPaid = () => {
    handleVefifyPaymentClaim(paymentClaim.id);
  };

  const handleSkip = () => {
    handleNext();
  };

  const requestList = () => (
    <List component='nav' aria-label='mailbox folders'>
      {Object.entries(ticketMap).map(
        ([key, value]) =>
          value.unpaid.length > 0 && (
            <Fragment key={key}>
              <ListItem button>
                <ListItemText primary={key} />
                <ListItemIcon>
                  <Badge badgeContent={value.unpaid.length} max={9999} color='primary' />
                </ListItemIcon>
              </ListItem>
              <Divider />
            </Fragment>
          ),
      )}
    </List>
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby='payment-dialog-title'
      aria-describedby='payment-dialog-description'>
      <DialogTitle id='payment-dialog-title'>Verify Payment?</DialogTitle>
      <DialogContent>
        <Typography variant='h6'>{`${paymentClaim?.createdBy}: ${numUnpaid} unpaid of ${
          numPaid + numUnpaid
        } total requested`}</Typography>
        {requestList()}
        <Box mb={1}>
          <Typography>Name: {paymentClaim?.name}</Typography>
        </Box>
        <Box mb={1}>
          <Typography>Email: {paymentClaim?.email}</Typography>
        </Box>
        <Box mb={1}>
          <Typography>Note: {paymentClaim?.note}</Typography>
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary' autoFocus>
          Cancel
        </Button>
        <Button onClick={handleSkip} color='primary'>
          Skip
        </Button>
        <Button color='primary' onClick={handleMarkSpecifiedPaid}>
          {`Mark ${paymentClaim?.ticketNumbers.length} Paid`}
        </Button>
        {totalClaimed !== paymentClaim?.ticketNumbers.length && (
          <Button color='primary' onClick={handleMarkAllPaid} display='none'>
            {`Mark ${totalClaimed} Paid`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PaymentVerifierDialog;
