import { Box, Button, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import WinnerCard from './winnerCard';
import { HashLink } from 'react-router-hash-link';

const WinnerDisplay = ({ rdoResponse, winningTickets, raffleId }) => {
  const random = encodeURIComponent(btoa(JSON.stringify(rdoResponse?.result.random))).replace(/%20/g, '+');
  const signature = encodeURIComponent(rdoResponse?.result.signature).replace(/%20/g, '+');
  const rdoVerificationLink = `https://api.random.org/signatures/form?format=json&random=${random}&signature=${signature}`;

  return (
    <>
      <Typography variant='h5' component='h2' style={{ textAlign: 'center', paddingBottom: '1rem' }}>
        Winners
      </Typography>
      {rdoResponse ? (
        <>
          <Box mb={2} border={'1px outset'} display='flex' justifyContent='space-between' marginX={'5%'}>
            <Button color={'primary'} href={rdoVerificationLink} target='_blank' rel='noopener noreferrer'>
              Verify Winner(s)
            </Button>
            <Button
              color={'primary'}
              component={HashLink}
              smooth
              to={`/winner-verification#${raffleId}`}
              variant={'text'}>
              View verification info
            </Button>
            <Button color={'primary'} component={HashLink} smooth to='/faq#verify-winner' variant={'text'}>
              How To Verify Winners
            </Button>
          </Box>

          <Grid container spacing={2} direction='row' justify='flex-start' alignItems='flex-start'>
            {winningTickets?.map((ticket) => (
              <Grid item sm={12} md={winningTickets?.length > 1 ? 6 : 12} key={ticket.id}>
                <WinnerCard ticket={ticket} />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <WinnerCard />
      )}
      <Divider style={{ marginTop: '2rem' }} />
    </>
  );
};

export default WinnerDisplay;
