import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Faq = ({ faq, initiallyExpanded }) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const handleClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <>
      <section id={faq.id} />
      <Accordion style={{ marginTop: '1rem' }} expanded={expanded} onClick={handleClick}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ fontWeight: 'bold' }}>{faq?.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>{faq?.description}</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default Faq;
