import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChatContext } from '../context/ChatContext';
import webstomp from 'webstomp-client';
import { getEnvVariable } from '../utils/envUtils';
import { useAuth } from '../hooks/useAuth';
import useHttpClient from '../hooks/useHttpClient';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { HTTP_ACTION } from '../utils/constants';

const ChatProvider = ({ children }) => {
  const { user } = useAuth();
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [activeChat, setActiveChat] = useState();
  const [sendMessage, setSendMessage] = useState(() => () => {});
  const [chatConversations, setChatConversations] = useState();
  const chatSocket = useRef(null);
  const chatClient = useRef(null);
  const chatSubscription = useRef(null);

  const { httpRequest: getChatConversations } = useHttpClient({
    url: '/communication-service/chat/conversations',
  });

  const { httpRequest: markConversationAsRead } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
  });

  const { httpRequest: getConversation } = useHttpClient();

  const updateActiveChat = useCallback(
    async (activeConversation) => {
      const conversation = (
        await getConversation({ url: `/communication-service/chat/conversations/${activeConversation.withUser}` })
      ).response?.data;
      setActiveChat({ withUser: activeConversation.withUser, conversation });
    },
    [getConversation],
  );

  const openChat = useCallback(
    (username) => {
      updateActiveChat({ withUser: username });
      setIsChatOpen(true);
    },
    [updateActiveChat, setIsChatOpen],
  );

  // sent messages also end up being received once they were successfully sent so we should put message sent logic here as well
  const recieveMessage = useCallback(
    (message) => {
      const userSentMessage = user.username.toLowerCase() === message.createdBy.toLowerCase();
      const withUser = userSentMessage ? message.recipient : message.createdBy;

      if (activeChat?.withUser.toLowerCase() === withUser.toLowerCase()) {
        updateActiveChat(activeChat);
      } else if (!activeChat) {
        updateActiveChat({ withUser: message.createdBy });
      }

      if (!userSentMessage) {
        if (!chatConversations.some((convo) => convo.withUser.toLowerCase() === message.createdBy.toLowerCase())) {
          setChatConversations([
            ...chatConversations,
            { withUser: message.createdBy, lastTime: new Date(), numUnread: 1 },
          ]);
        } else {
          setChatConversations((prev) =>
            prev.map((convo) =>
              convo.withUser.toLowerCase() === message.createdBy.toLowerCase()
                ? { ...convo, numUnread: convo.numUnread + 1 }
                : convo,
            ),
          );
        }
      } else {
        if (!chatConversations.some((convo) => convo.withUser.toLowerCase() === withUser.toLowerCase())) {
          setChatConversations([...chatConversations, { withUser: withUser, lastTime: new Date() }]);
          setActiveChat({
            withUser: withUser,
            conversation: [
              { from: user.username, message: message.message, recipient: withUser, timeSent: new Date() },
            ],
          });
        }
      }
    },
    [activeChat, chatConversations, updateActiveChat, user],
  );

  const chatConnect = useCallback(() => {
    if (user) {
      const commUrl = new URL(getEnvVariable('apiGateway'));
      let protocol = 'wss';
      let port = ':443';
      if (commUrl.protocol === 'http:') {
        protocol = 'ws';
        port = '';
      }

      if (chatSocket.current) {
        chatSocket.current.close();
      }

      chatSocket.current = new ReconnectingWebSocket(
        `${protocol}://${commUrl.host}${port}/communication-service/chat-sock`,
      );
      chatClient.current = webstomp.over(chatSocket.current, { debug: false });

      chatSocket.current.onopen = () => {
        chatClient.current.connect({ Authorization: `Bearer ${user.accessToken}` }, () => {
          chatSubscription.current = chatClient.current.subscribe('/user/topic/messages', (rawMessage) => {
            const message = JSON.parse(rawMessage.body);
            recieveMessage(message);
          });
        });
      };
      chatSocket.current.addEventListener('close', (event) => {
        if (event.target instanceof WebSocket) {
          setTimeout(function () {
            chatSocket.current.reconnect();
          }, 1000);
        }
      });
    }
  }, [recieveMessage, user]);

  const markConversationRead = useCallback(
    (username) => {
      markConversationAsRead({ url: `/communication-service/chat/conversations/${username}/mark-read` });
      setChatConversations((prev) =>
        chatConversations.map((convo) => (convo.withUser === username ? { ...convo, numUnread: 0 } : convo)),
      );
    },
    [chatConversations, markConversationAsRead],
  );

  useEffect(() => {
    chatConnect();
  }, [chatConnect]);

  useEffect(() => {
    setSendMessage(() => (recipient, message) => {
      if (user?.username) {
        chatClient.current.send('/app/chat', JSON.stringify({ recipient, message }));

        // all send and receive logic is centralized in recevieMessage because a sent message ends up there on success
        // if we do anything here we should have a "sending message" state get updated or something.
      }
    });
  }, [chatConversations, user]);

  useEffect(() => {
    async function fetchChatConversation() {
      if (user) {
        const conversations = (await getChatConversations()).response?.data;

        setChatConversations(conversations);
        if (conversations?.length > 0) {
          updateActiveChat(conversations[0]);
        }
      }
    }
    fetchChatConversation();
  }, [getChatConversations, updateActiveChat, user]);

  const chatContextValue = {
    conversations: chatConversations,
    activeChat,
    updateActiveChat,
    sendMessage,
    isChatOpen,
    setIsChatOpen,
    openChat,
    markConversationRead,
  };

  return <ChatContext.Provider value={chatContextValue}>{children}</ChatContext.Provider>;
};

export default ChatProvider;
