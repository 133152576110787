import React, { useCallback, useEffect, useRef, useState } from 'react';
import PaymentVerifierDialog from './paymentVerifierDialog';
import ConfirmationDialog from '../confirmationDialog';

const PaymentVerifier = ({ isOpen, handleClose, verifyPayment, raffle, refreshRaffle, ...rest }) => {
  const [paymentClaimIndex, setPaymentClaimIndex] = useState(-1);
  const [paymentClaim, setPaymentClaim] = useState();
  const [requestorsPaymentClaims, setRequestorsPaymentClaims] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [unverifiedPaymentClaims, setUnverifiedPaymentClaims] = useState([]);
  const [allowOpenPaymentModal, setAllowOpenPaymentModal] = useState(false);
  const [showNoMorePaymentClaimsModal, setShowNoMorePaymentClaimsModal] = useState(false);

  // needs to be a ref so it doesnt trigger useEffects
  const viewedPaymentClaimIdsRef = useRef([]);

  const handleCloseNoMorePaymentsModal = useCallback(() => {
    handleClose();
    setShowNoMorePaymentClaimsModal(false);
    setAllowOpenPaymentModal(true);
  }, [handleClose]);

  const handleNoMorePaymentClaims = useCallback(() => {
    setAllowOpenPaymentModal(false);
    setShowNoMorePaymentClaimsModal(true);
  }, []);

  const handleVefifyAllPaymentClaims = useCallback(async () => {
    const paymentClaimIds = requestorsPaymentClaims.map((paymentClaim) => paymentClaim.id);
    let verifyResponse = await verifyPayment({
      requestBody: { paymentClaimIds },
    });

    if (!verifyResponse?.error) {
      refreshRaffle();
    }
  }, [refreshRaffle, requestorsPaymentClaims, verifyPayment]);

  const handleVefifyPaymentClaim = useCallback(
    async (paymentClaimId) => {
      const verifyResponse = await verifyPayment({
        requestBody: { paymentClaimIds: [paymentClaimId] },
      });

      if (!verifyResponse?.error) {
        refreshRaffle();
      }
    },
    [refreshRaffle, verifyPayment],
  );

  const handleNext = () => {
    if (paymentClaim) {
      viewedPaymentClaimIdsRef.current = [...viewedPaymentClaimIdsRef.current, paymentClaim.id];
    }

    setNextPaymentClaim(paymentClaimIndex, unverifiedPaymentClaims);
  };

  const flashModal = () => {
    setAllowOpenPaymentModal(false);
    setTimeout(() => {
      setAllowOpenPaymentModal(true);
    }, 100);
  };

  const setNextPaymentClaim = useCallback(
    (currIndex, updatedUnverifiedPaymentClaims) => {
      let nextIndex = currIndex + 1;

      while (
        nextIndex < updatedUnverifiedPaymentClaims.length &&
        viewedPaymentClaimIdsRef.current.includes(updatedUnverifiedPaymentClaims[nextIndex].id)
      ) {
        nextIndex++;
      }

      if (nextIndex < updatedUnverifiedPaymentClaims.length) {
        setAllowOpenPaymentModal(true);
        if (nextIndex !== 0) {
          flashModal();
        }
        const newPaymentClaim = updatedUnverifiedPaymentClaims[nextIndex];
        setPaymentClaimIndex(nextIndex);
        setPaymentClaim(newPaymentClaim);
        setRequestorsPaymentClaims(
          updatedUnverifiedPaymentClaims?.filter((pc) => pc.createdBy === newPaymentClaim.createdBy && !pc.verified),
        );
      } else {
        handleNoMorePaymentClaims();
      }
    },
    [handleNoMorePaymentClaims],
  );

  useEffect(() => {
    if (isOpen) {
      refreshRaffle();
    } else {
      viewedPaymentClaimIdsRef.current = [];
    }
  }, [isOpen, refreshRaffle]);

  useEffect(() => {
    if (raffle) {
      const updatedUnverifiedPaymentClaims = raffle.paymentClaims?.filter((paymentClaim) => !paymentClaim.verified);
      setTickets(raffle.tickets);
      setUnverifiedPaymentClaims(updatedUnverifiedPaymentClaims);
      setNextPaymentClaim(-1, updatedUnverifiedPaymentClaims);
    }
  }, [raffle, setNextPaymentClaim]);

  return (
    <>
      <PaymentVerifierDialog
        isOpen={isOpen && allowOpenPaymentModal}
        handleClose={handleClose}
        handleNext={handleNext}
        paymentClaim={paymentClaim}
        requestorsPaymentClaims={requestorsPaymentClaims}
        tickets={tickets}
        handleVefifyAllPaymentClaims={handleVefifyAllPaymentClaims}
        handleVefifyPaymentClaim={handleVefifyPaymentClaim}
      />
      <ConfirmationDialog
        isOpen={isOpen && showNoMorePaymentClaimsModal}
        handleClose={handleCloseNoMorePaymentsModal}
        handleConfirm={handleCloseNoMorePaymentsModal}
        title='No More Payments To Verify'
        body={'There are no more payments to verify at this time. Try again later.'}
        hideCancel={true}
        okText='OK'
      />
    </>
  );
};

export default PaymentVerifier;
