import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import RaffleForm from '../pages/RaffleForm';
import HomePage from '../pages/Home';
import { LoginPage } from '../pages/Login';
import PageNotFound from '../pages/PageNotFound';
import { EditRaffle, ManageRaffle, RaffleAdmin } from '../pages/RaffleAdmin/index';
import ManageTickets from '../pages/RaffleAdmin/manageTickets';
import RaffleDetails from '../pages/RaffleDetails';
import { Registration } from '../pages/Registration';
import VerifyEmail from '../pages/VerifyEmail';
import ProtectedRoute from './ProtectedRoute';
import queryString from 'query-string';
import ErrorPage from '../pages/ErrorPage';
import FaqPage from '../pages/Faq';
import WinnerVerificationPage from '../pages/WinnerVerification';

const Routes = () => {
  const queryParams = queryString.parse(useLocation().search);

  return (
    <Switch>
      <Route exact path='/' render={() => <HomePage />} />
      <Route exact path='/login' render={(rProps) => <LoginPage {...rProps} />} />
      <Route exact path='/registration' render={(rProps) => <Registration {...rProps} />} />
      <Route exact path='/page-not-found' render={() => <PageNotFound />} />
      <Route exact path='/verify-email' render={() => <VerifyEmail />} />
      <Route exact path='/raffle/:id' render={(rProps) => <RaffleDetails {...rProps} />} />
      <Route exact path='/faq' render={(rProps) => <FaqPage {...rProps} />} />
      <Route exact path='/winner-verification' render={(rProps) => <WinnerVerificationPage {...rProps} />} />
      <Route exact path='/error' render={(rProps) => <ErrorPage {...rProps} />} />
      <ProtectedRoute exact path='/profile' render={() => <HomePage />} />
      <ProtectedRoute exact path='/raffles/manage' render={(rProps) => <RaffleAdmin {...rProps} />} />
      <ProtectedRoute exact path='/raffles/manage/:raffleId' render={(rProps) => <ManageRaffle {...rProps} />} />
      <ProtectedRoute
        exact
        path='/raffles/manage/:raffleId/tickets'
        render={(rProps) => <ManageTickets {...rProps} />}
      />
      <ProtectedRoute exact path='/raffles/edit/:raffleId' render={(rProps) => <EditRaffle {...rProps} />} />
      <ProtectedRoute exact path='/create-raffle' render={(rProps) => <RaffleForm {...rProps} />} />
      <Redirect from='/callback' to={queryParams?.redirect || '/'} />
      <Redirect to='/page-not-found' />
    </Switch>
  );
};

export default Routes;
