import React, { useEffect, useState } from 'react';
import { HTTP_ACTION } from '../../utils/constants';
import useHttpClient from '../../hooks/useHttpClient';
import Loader from '../loader';
import { PulseLoader } from 'react-spinners';
import Alert from '../alert';
import ImageUploader from 'react-images-upload';

const PictureUploader = ({ onChange, buttonText, singleImage, setImageUploading, initialValues }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { httpRequest: uploadFile, error, loading } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: '/raffle-service/files',
  });

  useEffect(() => {
    if (onChange) {
      onChange(uploadedFiles.map((uploadedFile) => uploadedFile.uploadedFileName));
    }
  }, [uploadedFiles, onChange]);

  useEffect(() => {
    if (setImageUploading) {
      setImageUploading(loading);
    }
  }, [loading, setImageUploading]);

  useEffect(() => {
    if (initialValues) {
      setUploadedFiles(
        initialValues.map((img) => {
          return { uploadedFileName: img, file: { name: img } };
        }),
      );
    }
  }, [initialValues]);

  // imageFiles contains the url for both data files and image links which is stupid but manageable
  const onImageListChange = async (dataFiles, imageFiles) => {
    const numAdded = imageFiles.length - uploadedFiles.length;

    // only data files can be added
    if (numAdded > 0) {
      const newFiles = [];
      for (let i = 0; i < numAdded; i++) {
        const uploadedImage = await uploadImage(
          dataFiles[uploadedFiles.length - (imageFiles.length - dataFiles.length) + i],
        );

        if (uploadedImage) {
          newFiles.push(uploadedImage);
        }
      }
      setUploadedFiles([...uploadedFiles, ...newFiles]);
    } else if (numAdded < 0) {
      removeUploadedFile(dataFiles, imageFiles);
    } else {
      if (singleImage && dataFiles.length === 1) {
        const uploadedImage = await uploadImage(dataFiles[0]);
        setUploadedFiles([uploadedImage]);
      }
    }
  };

  const uploadImage = async (addedFile) => {
    var formData = new FormData();
    formData.append('file', addedFile);
    const uploadResponse = (await uploadFile({ requestBody: formData })).response;

    if (uploadResponse) {
      return { file: addedFile, uploadedFileName: uploadResponse.data.fileName };
    } else {
      return undefined;
    }
  };

  const removeUploadedFile = (dataFiles, imageFiles) => {
    const uploadedFilesCopy = [...uploadedFiles];
    const fileList = [...imageFiles, ...dataFiles];

    for (let i = 0; i < uploadedFilesCopy.length; i++) {
      const file = uploadedFilesCopy[i].file;
      if (
        i + 1 > fileList.length ||
        (file.name !== fileList[i] && file.name !== fileList[i].name) ||
        file.size !== fileList[i].size
      ) {
        uploadedFilesCopy.splice(i, 1);
        setUploadedFiles(uploadedFilesCopy);
        break;
      }
    }
  };

  return (
    <>
      <Alert show={error} severity='error'>
        Error uploading images. Remove the images below and try again: {error && error.message ? error.message : error}
      </Alert>
      <Loader loading={loading} Spinner={PulseLoader} size={15} overlay={true} overlayText='Uploading Images...'>
        <ImageUploader
          defaultImages={initialValues}
          withPreview={true}
          withLabel={false}
          withIcon={false}
          singleImage={singleImage}
          buttonText={buttonText}
          onChange={onImageListChange}
          imgExtension={['.jpg', 'jpeg', 'webp', '.gif', '.png', '.tiff', 'bmp']}
          maxFileSize={1024 * 1024 * 10}
          fileSizeError='file size must be less than 10MB'
          fileTypeError='file type not supported (jpg, jpeg, webp, gif, png, tiff, bmp)'
        />
      </Loader>
    </>
  );
};

export default PictureUploader;
