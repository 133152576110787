import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, Button, CardHeader } from '@material-ui/core';
import ConfirmationDialog from '../confirmationDialog';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
    marginTop: '1rem',
  },
  header: {
    textAlign: 'center',
    marginBottom: '-1rem',
  },
  codeBlock: {
    backgroundColor: 'rgba(0,0,0,.05)',
  },
  infoLine: {
    paddingTop: '1rem',
  },
});

const WinnerVerificationCard = ({ raffle }) => {
  const { rdoResponse } = raffle;
  const classes = useStyles();
  const random = encodeURIComponent(btoa(JSON.stringify(rdoResponse?.result.random))).replace(/%20/g, '+');
  const signature = encodeURIComponent(rdoResponse?.result.signature).replace(/%20/g, '+');
  const rdoVerificationLink = `https://api.random.org/signatures/form?format=json&random=${random}&signature=${signature}`;

  return (
    <Card className={classes.root} variant='outlined'>
      <section id={raffle.id} />
      <CardHeader
        className={classes.header}
        title={
          <Typography variant='h5' component='h5'>
            Verification Info - {raffle.title}
          </Typography>
        }
      />
      <Box display='flex' justifyContent='space-between' marginX={'5%'}>
        <Button color={'primary'} href={rdoVerificationLink} target='_blank' rel='noopener noreferrer'>
          Verify Winner(s)
        </Button>
        <Button color={'primary'} component={RouterLink} to={`/raffle/${raffle.id}`} variant={'text'}>
          Raffle Link
        </Button>
        <ConfirmationDialog
          title='Raw Response'
          body={JSON.stringify(rdoResponse, null, 2)}
          hideCancel={true}
          okText='Close'
          ClickComponent={<Button color={'primary'}>Show RDO Response</Button>}
        />
      </Box>
      <CardContent style={{ wordBreak: 'break-all' }}>
        <Typography className={classes.infoLine} style={{ marginTop: '-1rem' }}>
          Winning number(s): <code className={classes.codeBlock}>{`${rdoResponse?.result.random.data.join()}`}</code>
        </Typography>
        <Typography className={classes.infoLine}>
          Serial Number: <code className={classes.codeBlock}>{`${rdoResponse?.result.random.serialNumber}`}</code>
        </Typography>
        <Typography className={classes.infoLine}>
          Random: <code className={classes.codeBlock}>{`${JSON.stringify(rdoResponse?.result.random)}`}</code>
        </Typography>
        <Typography className={classes.infoLine}>
          Signature: <code className={classes.codeBlock}>{`${rdoResponse?.result.signature}`}</code>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default WinnerVerificationCard;
