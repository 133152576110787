import React, { useCallback, useEffect, useState } from 'react';
import { Badge, Divider, Drawer, makeStyles } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import { useSidebar } from '../../hooks/useSidebar';
import { Link } from 'react-router-dom';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import { useAuth } from '../../hooks/useAuth';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ReactComponent as LogoutIcon } from '../../assets/logout_12.svg';
import { ReactComponent as LoginIcon } from '../../assets/login_12.svg';
import useHttpClient from '../../hooks/useHttpClient';
import Notification from '../notification';
import { Fragment } from 'react';
import { HTTP_ACTION } from '../../utils/constants';

const useLocalStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 1,
  },
  title: {
    color: theme.palette.primary.main,
  },
  username: {
    color: theme.palette.primary.secondary,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  appName: {
    color: 'unset',
    textDecoration: 'none',
  },
  rightLinks: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0, 1),
    minWidth: '220px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  tollbarHeader: {
    flexGrow: 1,
    textAlign: 'center',
    marginLeft: '-48px',
    fontWeight: 'bold',
  },
  notificationDrawer: {
    maxWidth: '350px',
  },
}));

const Header = () => {
  const { toggleSidebar } = useSidebar();
  const classes = useLocalStyles();
  const { logout, loggedIn } = useAuth();
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const { httpRequest: getNotifications, data: notifications } = useHttpClient({
    url: '/communication-service/notifications',
  });
  const { httpRequest: markSeen } = useHttpClient({
    url: '/communication-service/notifications/mark-seen',
    httpAction: HTTP_ACTION.POST,
  });

  const handleNotificationDrawerOpen = useCallback(() => {
    setNotificationDrawerOpen(true);
  }, []);

  const handleNotificationDrawerClose = useCallback(async () => {
    setNotificationDrawerOpen(false);
    if (notifications?.some((n) => !n.seenByUser)) {
      await markSeen({ requestBody: { notificationIds: notifications.map((n) => n.id) } });
      getNotifications();
    }
  }, [getNotifications, markSeen, notifications]);

  useEffect(() => {
    if (loggedIn) {
      getNotifications();
    }
  }, [getNotifications, loggedIn]);

  return (
    <>
      <AppBar position='static' className={classes.appBar}>
        <Toolbar>
          <IconButton
            color={'primary'}
            aria-label='open menu'
            edge='start'
            onClick={toggleSidebar}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant='h6' noWrap>
            <Link className={classes.appName} to='/'>
              ZapRaffle
            </Link>
          </Typography>
          <List className={classes.rightLinks}>
            {loggedIn && (
              <>
                <IconButton onClick={handleNotificationDrawerOpen} style={{ minWidth: 0 }}>
                  <Badge badgeContent={notifications?.filter((n) => !n.seenByUser).length} color='primary'>
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton onClick={logout} style={{ fontSize: 'inherit' }}>
                  Logout
                  <LogoutIcon style={{ marginLeft: '.25rem', marginTop: '.15rem' }} />
                </IconButton>
              </>
            )}
            {!loggedIn && window.location.pathname !== '/login' && (
              <Link to={'/login'}>
                <ListItem button>
                  <ListItemIcon>
                    Login
                    <LoginIcon style={{ marginLeft: '.25rem', marginTop: '.15rem' }} />
                  </ListItemIcon>
                </ListItem>
              </Link>
            )}
          </List>
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: classes.notificationDrawer }}
        disableBackdropTransition={true}
        anchor='right'
        open={notificationDrawerOpen}
        onClose={handleNotificationDrawerClose}>
        <div className={classes.toolbar}>
          <IconButton onClick={handleNotificationDrawerClose}>
            <ChevronRightIcon />
          </IconButton>
          <Typography className={classes.tollbarHeader}>NOTIFICATIONS</Typography>
        </div>
        <Divider />
        <List>
          {notifications?.map((notification) => (
            <Fragment key={notification.id}>
              <Notification notification={notification} closeNotificationMenu={handleNotificationDrawerClose} />
            </Fragment>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Header;
