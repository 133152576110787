import React from 'react';
import AuthProvider from '../providers/authProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../routes';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Sidebar from '../components/sidebar';
import Header from '../components/header';
import ContentContainer from '../components/contentContainer';
import SidebarProvider from '../providers/sidebarProvider';
import Chat from '../components/chat';
import ChatProvider from '../providers/chatProvider';

const palette = {
  primary: { main: '#205BB9' },
  secondary: { main: '#ED7119' },
};

const theme = createMuiTheme({ palette });

window.onerror = (message, source, lineno, colno, error) => {
  console.error({ message, source, lineno, colno, error });
  window.location.href = '/error';
};

window.onunhandledrejection = (error) => {
  console.error({ error });
  window.location.href = '/error';
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <SidebarProvider>
        <Router>
          <AuthProvider>
            <ChatProvider>
              <Chat />
              <Header />
              <Sidebar />
              <ContentContainer>
                <Routes />
              </ContentContainer>
            </ChatProvider>
          </AuthProvider>
        </Router>
      </SidebarProvider>
    </ThemeProvider>
  );
};

export default App;
