import React, { useEffect, useLayoutEffect, useRef } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Avatar, Box, Grid, ListItemIcon, Typography, makeStyles } from '@material-ui/core';
import ReactTimeago from 'react-timeago';
import { useAuth } from '../../hooks/useAuth';
import { convertToFriendlyDate, convertUtcStringToCurrentTimezone } from '../../utils/dataUtils';

const useStyles = makeStyles({
  chatSection: {
    width: '100%',
    height: '80vh',
  },

  rightChatBox: {
    display: 'inline-block',
    overflowWrap: 'anywhere',
    maxWidth: '60%',
    minWidth: '25%',
    marginLeft: 'auto',
    marginRight: '1rem',
    borderRadius: '15px',
    background: '#00bfb6',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'arial',
    position: 'relative',

    '&::before': {
      content: '""',
      width: '0px',
      height: '0px',
      position: 'absolute',
      borderLeft: '15px solid #00bfb6',
      borderRight: '15px solid transparent',
      borderTop: '15px solid #00bfb6',
      borderBottom: '15px solid transparent',
      right: '-16px',
      top: '0px',
    },
  },
  leftChatBox: {
    display: 'inline-block',
    overflowWrap: 'anywhere',
    maxWidth: '60%',
    minWidth: '25%',
    marginRight: 'auto',
    marginLeft: '1rem',
    borderRadius: '15px',
    background: '#00bfb6',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    fontWeight: '900',
    fontFamily: 'arial',
    position: 'relative',

    '&::before': {
      content: '""',
      width: '0px',
      height: '0px',
      position: 'absolute',
      borderLeft: '15px solid transparent',
      borderRight: '15px solid #00bfb6',
      borderTop: '15px solid #00bfb6',
      borderBottom: '15px solid transparent',
      left: '-16px',
      top: '0px',
    },
  },
  leftTime: {
    marginLeft: '1rem',
  },
  rightTime: {
    marginRight: '1rem',
  },
  messageArea: {
    overflowY: 'auto',
    height: '50vh',
  },
});

const ChatList = ({ messages = [] }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const messageArea = useRef(null);
  const endOfMessageArea = useRef(null);

  // calculated before render and used in useEffect because we need this value pre render to detect if we are at the bottom
  // otherwise the dom is updated and we will never be at the bottom in the useEffect
  const scrollIsAtBottom =
    messageArea.current?.scrollHeight - messageArea.current?.offsetHeight - messageArea.current?.scrollTop < 100;

  useLayoutEffect(() => {
    endOfMessageArea.current.scrollIntoView();
  }, []);

  useEffect(() => {
    if (scrollIsAtBottom) {
      endOfMessageArea.current.scrollIntoView();
    }
  }, [messages, scrollIsAtBottom]);

  const NoMessageComponent = () =>
    !messages?.length && (
      <Box textAlign={'center'}>
        <Typography component='div' variant='h4'>
          No Chat History
        </Typography>
      </Box>
    );

  return (
    <List className={classes.messageArea} ref={messageArea}>
      <NoMessageComponent />
      {messages?.map((message) => {
        const received = message.from.toUpperCase() !== user?.username.toUpperCase();
        const localTimeSent = convertUtcStringToCurrentTimezone(message.timeSent);
        return (
          <ListItem key={message.timeSent}>
            <Grid container style={{ textAlign: received ? 'left' : 'right' }}>
              <Grid item xs={12}>
                {received && (
                  <ListItemIcon>
                    <Avatar alt={message.from}>{message.from.substring(0, 2)}</Avatar>
                  </ListItemIcon>
                )}
                <ListItemText
                  className={received ? classes.leftChatBox : classes.rightChatBox}
                  align={received ? 'left' : 'right'}
                  primary={message.message}></ListItemText>
                {!received && (
                  <ListItemIcon>
                    <Avatar alt={message.from}>{message.from.substring(0, 2)}</Avatar>
                  </ListItemIcon>
                )}
              </Grid>
              <Grid item xs={12}>
                <ListItemText
                  className={received ? classes.leftTime : classes.rightTime}
                  align={received ? 'left' : 'right'}
                  secondary={
                    <ReactTimeago date={localTimeSent} title={convertToFriendlyDate(localTimeSent)} />
                  }></ListItemText>
              </Grid>
            </Grid>
          </ListItem>
        );
      })}
      <div ref={endOfMessageArea} />
    </List>
  );
};

export default ChatList;
