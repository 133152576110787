import React from 'react';
import { useSidebar } from '../../hooks/useSidebar';
import { Box } from '@material-ui/core';

const ContentContainer = ({ children }) => {
  const { width } = useSidebar();
  return (
    <Box display='flex' justifyContent='center' style={{ paddingLeft: width }}>
      {children}
    </Box>
  );
};

export default ContentContainer;
