import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as TicketIcon } from '../../assets/ticket_36.svg';
import { useAuth } from '../../hooks/useAuth';
import { useFormContext } from 'react-hook-form';

const disabledColor = 'lightgrey';
const checkedActive = '#83c124';
const uncheckedActive = '#bb9f6a';
const ownedColor = '#57f9df';

const getActiveFill = ({ disabled, checked, owned }) => {
  if (disabled) {
    return disabledColor;
  } else if (owned) {
    return ownedColor;
  }
  return checked ? checkedActive : uncheckedActive;
};
const getDefaultFill = ({ disabled, checked, owned }) => {
  if (disabled) {
    return disabledColor;
  } else if (owned) {
    return ownedColor;
  }
  return checked ? 'greenyellow' : '#F5CE85';
};

const useStyles = makeStyles({
  checkbox: {
    display: 'none',
  },
  overlay: {
    display: 'flex',
    position: 'relative',
    left: '4px',
    top: '36px',
    marginTop: '-25px',
    height: '25px',
    width: '40px',
    fontSize: '11px',
    transform: 'rotate(-45deg)',

    '& + svg': {
      fill: (props) => getDefaultFill(props),
    },
    '&:hover': {
      cursor: (props) => (!props.disabled && !props.owned ? 'pointer' : null),
    },
    '&:hover + svg': {},
    '&:active + svg': {
      fill: (props) => getActiveFill(props),
    },
  },
  number: {
    userSelect: 'none',
    margin: 'auto',
  },
});

const TicketCheckbox = ({ ticketNumber, owner }) => {
  const { user } = useAuth();
  const { register } = useFormContext();

  const disabled = owner && owner !== user?.username;
  const owned = owner && owner === user?.username;

  const [checked, setChecked] = useState(false);
  const classes = useStyles({ checked, disabled, owned });

  const ticketIndex = ticketNumber - 1;

  const handleChange = useCallback((e) => {
    setChecked(e.target.checked);
  }, []);

  // I tried to use Box in here instead of label and div and it severely impacted performance
  // anything we can do to improve performance of this component would be beneficial since we render a lot of them at once.
  return (
    <>
      <input
        ref={register}
        disabled={disabled || owned}
        checked={checked}
        onChange={handleChange}
        type='checkbox'
        className={classes.checkbox}
        id={`ticketCheckboxes[${ticketIndex}]`}
        name={`ticketCheckboxes[${ticketIndex}]`}
      />
      <label htmlFor={`ticketCheckboxes[${ticketIndex}]`} className={classes.overlay}>
        <div className={classes.number}>{ticketNumber}</div>
      </label>
      <TicketIcon />
    </>
  );
};

export default TicketCheckbox;
