import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';

const JustificationsDisplay = ({ justifications }) => {
  return (
    <>
      <Typography variant='h5' component='h2' style={{ textAlign: 'center', paddingBottom: '2rem' }}>
        Price Justifications
      </Typography>
      <Grid container spacing={2} direction='row' justify='flex-start' alignItems='flex-start'>
        {justifications?.map((justification, index) => (
          <Grid item xs={12} key={justification.id || index}>
            <Typography style={{ textAlign: 'center' }}>{justification.justification}</Typography>
          </Grid>
        ))}
      </Grid>
      <Divider style={{ marginTop: '2rem', marginBottom: '2rem' }} />
    </>
  );
};

export default JustificationsDisplay;
