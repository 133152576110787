import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import useHttpClient from '../../hooks/useHttpClient';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import TicketSelector from '../../components/ticketSelector';
import hash from 'object-hash';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import MarkPaidDialog from '../../components/markPaidDialog';
import { HTTP_ACTION } from '../../utils/constants';
import { useAuth } from '../../hooks/useAuth';
import TicketList from '../../components/ticketList';
import Carousel from 'react-material-ui-carousel';
import PrizesDisplay from '../../components/prizesDisplay';
import JustificationsDisplay from '../../components/justificationsDisplay';
import { convertUtcStringToFriendlyCurrentTimezone } from '../../utils/dataUtils';
import WinnerDisplay from '../../components/winnerDisplay';
import { useChat } from '../../hooks/useChat';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '345px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  userActionButton: {
    width: '220px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: '1rem',
    },
  },
  userActionMenu: {
    display: 'flex',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
}));

const RaffleDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { user, loggedIn } = useAuth();
  const { openChat } = useChat();

  const [showMarkPaidDialog, setShowMarkPaidDialog] = useState(false);
  const [showTicketListDialog, setShowTicketListDialog] = useState(false);
  const [claimPaymentSuccess, setClaimPaymentSuccess] = useState(false);
  const [unpaidTickets, setUnpaidTickets] = useState([]);

  const {
    data: raffle,
    httpRequest: getRaffle,
    error: getRaffleError,
    loading: getRaffleLoading,
  } = useHttpClient({
    url: `/raffle-service/raffles/${id}`,
    initialRequest: true,
  });

  const {
    httpRequest: claimPayment,
    error: claimPaymentError,
    loading: claimPaymentLoading,
  } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: `/raffle-service/raffles/${id}/payment-claim`,
  });

  const raffleImages = raffle ? [raffle.mainImage, ...raffle.additionalImages] : [];

  const onAssign = () => {
    getRaffle();
  };

  const handleMarkPaidDialogOpen = () => {
    setShowMarkPaidDialog(true);
  };

  const handleMarkPaidDialogClose = () => {
    setShowMarkPaidDialog(false);
  };

  const handleShowTicketListDialog = () => {
    setShowTicketListDialog(true);
  };

  const handleCloseTicketListDialog = () => {
    setShowTicketListDialog(false);
  };

  const handleClaimPayment = async (formvals) => {
    const claimPaymentResponse = (await claimPayment({ requestBody: formvals })).response;
    setShowMarkPaidDialog(false);

    if (claimPaymentResponse) {
      setClaimPaymentSuccess(true);
      getRaffle();
    }
  };
  const handleOpenChat = async () => {
    openChat(raffle?.createdBy);
  };

  useEffect(() => {
    if (raffle) {
      let claimedTickets = [];

      for (let paymentClaim of raffle.paymentClaims) {
        claimedTickets = claimedTickets.concat(paymentClaim.ticketNumbers);
      }

      let unpaid = raffle?.tickets?.filter(
        (ticket) => !ticket.paid && ticket.requester === user?.username && !claimedTickets.includes(ticket.number),
      );

      setUnpaidTickets(unpaid);
    }
  }, [raffle, user]);

  return (
    <Container component='main' maxWidth='md'>
      <Alert show={getRaffleError} severity='error' style={{ marginBottom: '1rem' }}>
        <b>{`Error getting raffle: ${getRaffleError}`}</b>
      </Alert>
      <Alert show={claimPaymentError} severity='error' style={{ marginBottom: '1rem' }}>
        <b>{`Error marking tickets paid: ${claimPaymentError}`}</b>
      </Alert>
      <Alert show={claimPaymentSuccess} severity='success' style={{ marginBottom: '1rem' }}>
        <b>{`Thanks for marking your tickets paid. The raffler will confirm your payment with the info you provided.`}</b>
      </Alert>
      <Typography component='h1' variant='h5' style={{ margin: '1rem 0rem' }}>
        Raffle Details
      </Typography>
      <Loader loading={getRaffleLoading || claimPaymentLoading}>
        <Paper style={{ padding: '1rem' }}>
          {loggedIn && (
            <Box className={classes.userActionMenu}>
              <Button
                className={classes.userActionButton}
                variant='contained'
                onClick={handleMarkPaidDialogOpen}
                color='primary'
                disabled={unpaidTickets.length === 0}>
                Mark My Tickets Paid
              </Button>
              <Button
                className={classes.userActionButton}
                variant='contained'
                onClick={handleShowTicketListDialog}
                color='primary'>
                View Full Ticket List...
              </Button>
              <Button className={classes.userActionButton} variant='contained' onClick={handleOpenChat} color='primary'>
                Chat With Raffler
              </Button>
            </Box>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={'header2'}>Item:</div>
              <div className={'body1'}>{raffle?.title}</div>
            </Grid>
            <Grid item xs={12}>
              <div className={'header2'}>Description:</div>
              <div className={'body1'}>{raffle?.description}</div>
            </Grid>
            <Grid item xs={12}>
              <div className={'header2'}>Raffle ID:</div>
              <div className={'body1'}>{raffle?.id}</div>
            </Grid>
            <Grid item xs={12}>
              <div className={'header2'}>Start time:</div>
              <div className={'body1'}>{convertUtcStringToFriendlyCurrentTimezone(raffle?.startTime)}</div>
            </Grid>
            <Grid item xs={12}>
              <div className={'header2'}>Item Value</div>
              <div className={'body1'}>${(raffle?.ticketPrice * raffle?.ticketQuantity)?.toLocaleString()}</div>
            </Grid>
            <Grid item xs={12}>
              <div className={'header2'}>Price per ticket:</div>
              <div className={'body1'}>${raffle?.ticketPrice?.toLocaleString()}</div>
            </Grid>
            <Grid item xs={12}>
              <div className={'header2'}>Total tickets :</div>
              <div className={'body1'}>{raffle?.ticketQuantity?.toLocaleString()}</div>
            </Grid>

            <Grid item xs={12}>
              <WinnerDisplay
                rdoResponse={raffle?.rdoResponse}
                winningTickets={raffle?.tickets?.filter((ticket) => ticket.prizes?.length > 0)}
                raffleId={raffle?.id}
              />
            </Grid>

            <Grid item xs={12}>
              <PrizesDisplay prizes={raffle?.prizes ?? []} />
            </Grid>

            <Grid item xs={12}>
              <JustificationsDisplay justifications={raffle?.justifications ?? []} />
            </Grid>

            <Grid item xs={12}>
              <Carousel navButtonsAlwaysVisible={raffleImages?.length > 1} autoPlay={false}>
                {raffleImages.map((image, i) => (
                  <CardMedia className={classes.media} image={image} title={raffle?.title} />
                ))}
              </Carousel>
            </Grid>
          </Grid>
          {loggedIn && (
            <>
              <Grid item xs={12}>
                <Divider />
                <Typography variant='h5' component='h2' style={{ textAlign: 'center', paddingTop: '2rem' }}>
                  Tickets
                </Typography>
              </Grid>
              <TicketSelector
                // use tickets hash as key to force unmount and remount so it loses form state
                // and doesnt maintain selected tickets
                key={hash(raffle ? raffle.tickets : {})}
                raffleId={id}
                ticketPrice={raffle?.ticketPrice}
                tickets={raffle?.tickets}
                onAssign={onAssign}
              />
            </>
          )}
        </Paper>
      </Loader>

      <MarkPaidDialog
        isOpen={showMarkPaidDialog}
        handleClose={handleMarkPaidDialogClose}
        handleClaimPayment={handleClaimPayment}
        raffleId={id}
        tickets={raffle?.tickets}
      />

      <Dialog
        open={showTicketListDialog}
        onClose={handleCloseTicketListDialog}
        aria-labelledby='ticket-list-dialog-title'
        aria-describedby='ticket-list-dialog-description'>
        <DialogTitle id='ticket-list-dialog-title'>Ticket List</DialogTitle>
        <DialogContent>
          <TicketList raffle={raffle} readOnly={true} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTicketListDialog} color='primary' autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default RaffleDetails;
