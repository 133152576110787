import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HomeIcon from '@material-ui/icons/Home';
import HelpIcon from '@material-ui/icons/Help';
import WorkIcon from '@material-ui/icons/Work';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSidebar } from '../../hooks/useSidebar';
import { useAuth } from '../../hooks/useAuth';

const useStyles = makeStyles(() => {
  const { width: drawerWidth } = useSidebar();
  return {
    drawerContainer: {
      overflow: 'auto',
    },
    persistentDrawerPaper: {
      width: drawerWidth,
      backgroundColor: '#eff1f4',
      borderRight: 0,
    },
    temporaryDrawerPaper: {
      width: '200px',
      backgroundColor: '#eff1f4',
      borderRight: 0,
    },
  };
});

const Sidebar = () => {
  const { sidebarOpen, closeSidebar } = useSidebar();
  const classes = useStyles();
  const { user } = useAuth();

  const linkList = [
    { title: 'Home', icon: <HomeIcon />, link: '/', show: true },
    { title: 'Create Raffle', icon: <AddBoxIcon />, link: '/create-raffle', show: user?.canCreateRaffles },
    { title: 'Manage Raffles', icon: <WorkIcon />, link: '/raffles/manage', show: user?.canCreateRaffles },
    { title: 'FAQ', icon: <HelpIcon />, link: '/faq', show: true },
  ];
  const drawer = (
    <div>
      <List>
        {linkList.map(
          ({ title, icon, link, show }) =>
            show && (
              <ListItem onClick={closeSidebar} button component={Link} key={title} to={link}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
            ),
        )}
      </List>
    </div>
  );
  return (
    <>
      <div className={classes.root}>
        <nav className={classes.drawerContainer} aria-label='mailbox folders'>
          <Hidden smUp>
            <Drawer
              variant='temporary'
              open={sidebarOpen}
              onClose={closeSidebar}
              classes={{
                paper: classes.temporaryDrawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}>
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown>
            <Drawer
              classes={{
                paper: classes.persistentDrawerPaper,
              }}
              variant='permanent'
              open>
              <Toolbar />
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
