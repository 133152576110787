import React, { useEffect, useState } from 'react';
import { Box, FormGroup, IconButton, TextField, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineOutlined from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { v4 as uuidv4 } from 'uuid';
import { useFormContext } from 'react-hook-form';
import { Fragment } from 'react';

const createJustification = () => {
  const listId = uuidv4();
  return { listId };
};

const JustificationForm = ({ justifications }) => {
  const [justificationList, setJustificationList] = useState();
  const { register, errors } = useFormContext();

  useEffect(() => {
    const tempJustifications = justifications.map((justification) => {
      const listId = uuidv4();
      return { ...justification, listId };
    });

    let updatedJustificationList;
    if (justificationList?.length > 0) {
      updatedJustificationList = justificationList;
    } else if (tempJustifications?.length > 0) {
      updatedJustificationList = tempJustifications;
    } else {
      updatedJustificationList = [createJustification()];
    }

    setJustificationList(updatedJustificationList);
  }, [justifications, justificationList]);

  const handleAddJustification = () => {
    setJustificationList([...justificationList, createJustification()]);
  };

  const handleRemoveJustification = (listId) => {
    const tempList = justificationList.filter((justification) => justification.listId !== listId);
    setJustificationList(tempList);
  };

  return (
    <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='h5' component='h2'>
        Price Justifications
        <IconButton color={'primary'} aria-label='add justification' edge='start' onClick={handleAddJustification}>
          <AddCircleIcon style={{ marginLeft: '3px' }} />
        </IconButton>
      </Typography>
      {justificationList?.map((justification) => (
        <Fragment key={justification.listId}>
          <TextField
            inputRef={register()}
            defaultValue={justification.id}
            id={`justificationId[${justification.listId}]`}
            name={`justificationId[${justification.listId}]`}
            style={{ display: 'none' }}
          />
          <FormGroup row>
            <TextField
              inputRef={register({
                required: 'Price justification is required',
              })}
              defaultValue={justification.justification}
              variant='outlined'
              margin='normal'
              id={`priceJustification[${justification.listId}]`}
              label='Price Justification'
              name={`priceJustification[${justification.listId}]`}
              error={!!errors?.priceJustification?.[justification.listId]}
              helperText={
                errors?.priceJustification?.[justification.listId] &&
                errors?.priceJustification?.[justification.listId]?.message
              }
              style={{ marginRight: '1rem' }}
            />
            {justificationList?.length > 1 && (
              <IconButton
                color={'primary'}
                aria-label='add justification'
                edge='start'
                onClick={() => {
                  handleRemoveJustification(justification.listId);
                }}>
                <RemoveCircleOutlineOutlined />
              </IconButton>
            )}
          </FormGroup>
        </Fragment>
      ))}
    </Box>
  );
};

export default JustificationForm;
