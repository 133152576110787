import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, IconButton, Radio, RadioGroup, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PrizeFields from './prizeFields';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useFormContext } from 'react-hook-form';

const createPrize = () => {
  const listId = uuidv4();
  return { listId };
};

const PrizeForm = ({ prizes, multipleWinners }) => {
  const [prizeList, setPrizeList] = useState();
  const { control } = useFormContext();

  useEffect(() => {
    const tempPrizes = prizes.map((prize) => {
      const listId = uuidv4();
      return { ...prize, listId };
    });

    let updatedPrizeList;
    if (prizeList?.length > 0) {
      updatedPrizeList = prizeList;
    } else if (tempPrizes?.length > 0) {
      updatedPrizeList = tempPrizes;
    } else {
      updatedPrizeList = [createPrize()];
    }

    setPrizeList(updatedPrizeList);
  }, [prizes, prizeList]);

  const handleAddPrize = () => {
    setPrizeList([...prizeList, createPrize()]);
  };

  const handleRemovePrize = (listId) => {
    const tempList = prizeList.filter((prize) => prize.listId !== listId);
    setPrizeList(tempList);
  };

  return (
    <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
      <Typography variant='h5' component='h2'>
        Prizes
        <IconButton color={'primary'} aria-label='add prize' edge='start' onClick={handleAddPrize}>
          <AddCircleIcon style={{ marginLeft: '3px' }} />
        </IconButton>
      </Typography>
      <Grid item xs={12} style={{ marginBottom: '1rem' }}>
        <Controller
          as={
            <RadioGroup aria-label='multiple winners?' name='multipleWinnersGroup' row>
              <FormControlLabel value='single' control={<Radio color='primary' />} label='One winner per raffle' />
              <FormControlLabel value='multiple' control={<Radio color='primary' />} label='One winner per prize' />
            </RadioGroup>
          }
          name='multipleWinners'
          control={control}
          defaultValue={multipleWinners ? 'multiple' : 'single'}
        />
      </Grid>
      {prizeList?.map((prize, index) => {
        return (
          <PrizeFields
            prize={prize}
            key={prize.listId}
            listId={prize.listId}
            listIndex={index}
            removePrize={handleRemovePrize}
            showRemove={prizeList?.length > 1}
          />
        );
      })}
    </Box>
  );
};

export default PrizeForm;
