import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { useCallback, useState } from 'react';
import Loader from '../../components/loader';
import useHttpClient from '../../hooks/useHttpClient';
import Alert from '../../components/alert';
import PaymentVerifier from '../../components/paymentVerifier';
import { useParams } from 'react-router-dom';
import { useStyles } from '../../hooks/useStyles';
import { HTTP_ACTION } from '../../utils/constants';
import { Box, Grid, makeStyles } from '@material-ui/core';
import RemoveUnpaidDialog from '../../components/removeUnpaidDialog';
import ChooseWinnersDialog from '../../components/chooseWinnersDialog';
import { Link as RouterLink } from 'react-router-dom';

const useLocalStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginRight: theme.spacing(1),
    },
  },
}));

const ManageRaffle = () => {
  const globalStyles = useStyles();
  const localStyles = useLocalStyles();
  const { raffleId } = useParams();
  const [showPaymentVerifier, setShowPaymentVerifier] = useState(false);
  const [showRemoveUnpaid, setShowRemoveUnpaid] = useState(false);
  const [showChooseWinners, setShowChooseWinners] = useState(false);
  const [removeUnpaidSuccessMessage, setRemoveUnpaidSuccessMessage] = useState();
  const [chooseWinnersSuccessMessage, setChooseWinnersSuccessMessage] = useState();

  const { data: raffle, httpRequest: getRaffle, error: getRaffleError, loading: getRaffleLoading } = useHttpClient({
    url: `/raffle-service/raffles/${raffleId}`,
    initialRequest: true,
  });

  const { httpRequest: verifyPayment, error: verifyPaymentError } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: `/raffle-service/raffles/${raffleId}/payment-claim/verify`,
  });

  const { httpRequest: removeUnpaid, error: removeUnpaidError } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: `/raffle-service/raffles/${raffleId}/remove-unpaid`,
  });

  const { httpRequest: chooseWinners, error: chooseWinnersError } = useHttpClient({
    httpAction: HTTP_ACTION.POST,
    url: `/raffle-service/raffles/${raffleId}/choose-winners`,
  });

  const handleShowPaymentVerifier = useCallback(() => {
    setShowPaymentVerifier(true);
  }, []);
  const handleClosePaymentVerifier = useCallback(() => {
    setShowPaymentVerifier(false);
  }, []);

  const handleShowRemoveUnpaid = useCallback(async () => {
    await getRaffle();
    setShowRemoveUnpaid(true);
  }, [getRaffle]);

  const handleShowChooseWinners = useCallback(async () => {
    await getRaffle();
    setShowChooseWinners(true);
  }, [getRaffle]);

  const handleCloseChooseWinners = useCallback(() => {
    setShowChooseWinners(false);
  }, []);

  const handleCloseRemoveUnpaid = useCallback(() => {
    setShowRemoveUnpaid(false);
  }, []);

  const handleRemoveUnpaid = useCallback(async () => {
    const removeUnpaidResponse = (await removeUnpaid()).response;

    if (removeUnpaidResponse) {
      setRemoveUnpaidSuccessMessage(
        `Removed the following unpaid users: ${removeUnpaidResponse.data.removedRequesters.join(', ')}`,
      );
    }

    handleCloseRemoveUnpaid();
  }, [handleCloseRemoveUnpaid, removeUnpaid]);

  const handleChooseWinner = async () => {
    const winners = (await chooseWinners()).response;

    if (winners && winners.data && winners.data.winningTickets) {
      const winnersMessage = winners.data.winningTickets.map((ticket) => `${ticket.number}:${ticket.owner}`);
      setChooseWinnersSuccessMessage(`Congrats on a successful raffle. Winners chosen: ${winnersMessage}`);
    }
  };

  return (
    <Container component='main' maxWidth='lg'>
      <Loader loading={getRaffleLoading}>
        <Alert show={getRaffleError} severity='error'>
          <b>{`Error retrieving raffle: ${getRaffleError}`}</b>
        </Alert>
        <Alert show={verifyPaymentError} severity='error'>
          <b>{`Error verifying payment: ${verifyPaymentError}`}</b>
        </Alert>
        <Alert show={removeUnpaidError} severity='error'>
          <b>{`Error verifying payment: ${removeUnpaidError}`}</b>
        </Alert>
        <Alert show={removeUnpaidSuccessMessage} severity='success'>
          <b>{removeUnpaidSuccessMessage}</b>
        </Alert>
        <Alert show={chooseWinnersError} severity='error'>
          <b>{`Error choosing winners: ${chooseWinnersError}`}</b>
        </Alert>
        <Alert show={chooseWinnersSuccessMessage} severity='success'>
          <b>{chooseWinnersSuccessMessage}</b>
        </Alert>
        <Paper className={globalStyles.paper}>
          <Typography component='h1' variant='h5' style={{ margin: '1rem 0rem' }}>
            {`Managing: "${raffle?.title}"`}
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Box className={localStyles.buttonGroup}>
                <Button onClick={handleShowPaymentVerifier} variant='outlined' color='primary'>
                  Verify Payments...
                </Button>
                <Button onClick={handleShowRemoveUnpaid} variant='outlined' color='primary'>
                  Remove Unpaid...
                </Button>
                <Button onClick={handleShowChooseWinners} variant='outlined' color='primary'>
                  Choose Winner(s)...
                </Button>
                <Button
                  variant='outlined'
                  color='primary'
                  component={RouterLink}
                  to={`/raffles/manage/${raffleId}/tickets`}>
                  Manually Edit Tickets...
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
        <PaymentVerifier
          raffle={raffle}
          refreshRaffle={getRaffle}
          isOpen={showPaymentVerifier}
          handleClose={handleClosePaymentVerifier}
          verifyPayment={verifyPayment}
        />
        <RemoveUnpaidDialog
          isOpen={showRemoveUnpaid}
          handleClose={handleCloseRemoveUnpaid}
          handleRemoveUnpaid={handleRemoveUnpaid}
          hasUnverifiedPaymentClaims={
            raffle?.paymentClaims?.filter((paymentClaim) => !paymentClaim.verified)?.length > 0
          }
          hasUnpaidTickets={raffle?.tickets?.filter((ticket) => ticket.owner && !ticket.paid)?.length > 0}
        />
        <ChooseWinnersDialog
          isOpen={showChooseWinners}
          handleClose={handleCloseChooseWinners}
          tickets={raffle?.tickets}
          numWinners={raffle?.multipleWinners ? raffle?.prizes?.length : 1}
          chooseWinners={handleChooseWinner}
          complete={raffle?.complete}
        />
      </Loader>
    </Container>
  );
};

export default ManageRaffle;
