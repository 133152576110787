import React from 'react';
import { Box, IconButton, TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import ImportIcon from '@material-ui/icons/SystemUpdateAlt';
import { getEnvVariable } from '../../utils/envUtils';
import { REGEX_PATTERN } from '../../utils/constants';
import useHttpClient from '../../hooks/useHttpClient';

const ImgurImport = ({ onImport }) => {
  const { register, handleSubmit, errors } = useForm({ shouldFocusError: true });

  const { httpRequest: getAlbumImages } = useHttpClient({
    useApiGateway: false,
    options: {
      headers: {
        Authorization: `Client-ID ${getEnvVariable('imgurClientId')}`,
      },
    },
  });

  const handleImportAlbum = async (formVals) => {
    const albumId = formVals.imgurAlbum.match(REGEX_PATTERN.IMGUR_LINK)[1];
    const images = (await getAlbumImages({ url: `https://api.imgur.com/3/album/${albumId}/images` })).response;
    onImport(
      images?.data?.data?.map((image) => {
        // we dont currently support mp4 format
        if (image.type === 'video/mp4') {
          return `https://i.imgur.com/${image.id}.gif`;
        } else {
          return image.link;
        }
      }),
    );
  };

  return (
    <Box display='flex' justifyContent='center'>
      <TextField
        inputRef={register({
          required: 'Enter an Imugur album link',
          pattern: { value: REGEX_PATTERN.IMGUR_LINK, message: 'Invalid Album Link' },
        })}
        variant='outlined'
        margin='normal'
        id={'imgurAlbum'}
        label='Import Imgur Album'
        name={'imgurAlbum'}
        error={!!errors?.imgurAlbum}
        helperText={errors?.imgurAlbum && errors?.imgurAlbum.message}
        placeholder='https://imgur.com/a/example'
        style={{ marginRight: '1rem' }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleSubmit(handleImportAlbum)} color='primary' aria-label='import'>
              <ImportIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default ImgurImport;
