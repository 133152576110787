import { Grid } from '@material-ui/core';
import React from 'react';
import WinnerVerificationCard from './winnerVerificationCard';

const WinnerVerificationDisplay = ({ completeRaffles }) => {
  return (
    <>
      <Grid container spacing={2} direction='row' justify='flex-start' alignItems='flex-start'>
        {completeRaffles
          ?.sort((a, b) => (a.completedAt > b.completedAt ? 1 : -1))
          .map((raffle) => (
            <Grid item sm={12} key={raffle.id}>
              <WinnerVerificationCard raffle={raffle} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default WinnerVerificationDisplay;
