import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
const ProtectedRoute = ({ render: C, ...routeProps }) => {
  const { loggedIn } = useAuth();
  return (
    <Route
      render={(rProps) =>
        loggedIn ? (
          <C {...rProps} />
        ) : (
          <Redirect to={`/login?redirect=${rProps.location.pathname}${rProps.location.search}`} />
        )
      }
      {...routeProps}
    />
  );
};

export default ProtectedRoute;
