import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import Alert from '../../components/alert';
import ImageEditor from '../../components/imageEditor';
import ImgurImport from '../../components/ImgurImport';
import JustificationForm from '../../components/justificationForm';
import Loader from '../../components/loader';
import PictureUploader from '../../components/pictureUploader';
import PrizeForm from '../../components/prizeForm';
import useHttpClient from '../../hooks/useHttpClient';
import { useStyles } from '../../hooks/useStyles';
import { HTTP_ACTION } from '../../utils/constants';
import { convertToNumber, convertToUtc, convertUtcStringToCurrentTimezone } from '../../utils/dataUtils';

const RaffleForm = ({ history, raffle }) => {
  const [imageList, setImageList] = useState([]);
  const [albumImages, setAlbumImages] = useState();
  const [mainImage, setMainImage] = useState();
  const [imageUploading, setImageUploading] = useState(false);
  const styles = useStyles();
  const formMethods = useForm({ shouldFocusError: true, multipleWinners: 'single' });
  const { handleSubmit, register, errors, getValues } = formMethods;
  const { httpRequest: submitRaffle, error, loading } = useHttpClient();

  const isLive = raffle && new Date() >= new Date(raffle?.startTime) && !raffle?.draft;

  const registerRaffle = async (values, successMessage) => {
    values.ticketQuantity = convertToNumber(values.ticketQuantity);
    values.ticketPrice = convertToNumber(values.ticketPrice);
    values.startTime = convertToUtc(values.raffleDateTime);

    const prizes = [];
    for (const key of Object.keys(values.prizeTitle)) {
      prizes.push({
        id: values.prizeId[key] !== '' ? values.prizeId[key] : null,
        title: values.prizeTitle[key],
        description: values.prizeDescription[key],
        prizeCategory: values.prizeCategory[key],
      });
    }

    const justifications = [];
    for (const key of Object.keys(values.priceJustification)) {
      justifications.push({
        id: values.justificationId[key] !== '' ? values.justificationId[key] : null,
        justification: values.priceJustification[key],
      });
    }

    const httpAction = raffle ? HTTP_ACTION.PUT : HTTP_ACTION.POST;
    const url = raffle ? `/raffle-service/raffles/${raffle.id}` : '/raffle-service/raffles';
    const submitResponse = await submitRaffle({
      requestBody: {
        ...values,
        multipleWinners: values.multipleWinners !== 'single',
        mainImage,
        additionalImages: imageList,
        prizes,
        justifications,
      },
      httpAction,
      url,
    });

    if (!submitResponse.error) {
      history.push(`/raffles/manage?message=${successMessage}`);
    } else {
      // jump to top of screen where error will display
      window.scrollTo(0, 0);
    }
  };

  const handleSaveForLater = async () => {
    const currentValues = await getValues();
    const draftValues = { ...currentValues, draft: true };
    registerRaffle(draftValues, 'raffle-saved-for-later');
  };
  const handlePublishRaffle = async () => {
    const currentValues = await getValues();
    const raffleValues = { ...currentValues, draft: false };

    await registerRaffle(raffleValues, raffle ? 'raffle-updated' : 'raffle-created');
  };

  const onAlbumImport = (imageLinks) => {
    setAlbumImages(imageLinks);
  };

  useEffect(() => {
    if (raffle) {
      setMainImage(raffle.mainImage);
      setImageList(raffle.additionalImages);
    }
  }, [raffle]);

  return (
    <Loader loading={loading} overlay>
      <Container component='main' maxWidth='md'>
        <Typography component='h1' variant='h5' style={{ margin: '1rem 0rem' }}>
          {raffle ? 'Updating Raffle' : 'Create a raffle'}
        </Typography>
        <Paper elevation={5} className={`${styles.paper} `}>
          <Alert show={error} severity='error' style={{ marginBottom: '1rem', width: 'calc(100% - 2rem)' }}>
            <b>{error}</b>
          </Alert>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(handlePublishRaffle)}>
              <Grid container spacing={2} style={{ marginTop: '1rem' }}>
                {raffle && (
                  <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                    <div style={{ margin: '0rem 0 0.5rem 0' }}>
                      {' '}
                      Updating Raffle: <span style={{ fontWeight: 300 }}>{raffle.title}</span>{' '}
                    </div>
                    <div>
                      Raffle ID: <span style={{ fontWeight: 300 }}>{raffle.id}</span>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    data-testid={'title'}
                    autoFocus={!raffle}
                    defaultValue={raffle?.title}
                    error={!!errors.title}
                    fullWidth
                    helperText={errors.title && errors.title?.message}
                    id='title'
                    name='title'
                    inputRef={register({
                      required: 'Raffle name is required',
                    })}
                    label='Raffle name'
                    type={'text'}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid={'description'}
                    defaultValue={raffle?.description}
                    error={errors.description}
                    helperText={errors.description && errors.description?.message}
                    fullWidth
                    id='description'
                    inputRef={register({
                      required: 'description is required',
                    })}
                    label='Raffle description'
                    multiline
                    name='description'
                    rows={4}
                    type={'text'}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'center' }}>
                  <Typography variant='h5' component='h2'>
                    Images
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ImageEditor
                    onImageSave={setMainImage}
                    setImageUploading={setImageUploading}
                    initialValue={raffle?.mainImage}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ImgurImport onImport={onAlbumImport} />
                </Grid>
                <Grid item xs={12}>
                  <PictureUploader
                    onChange={setImageList}
                    buttonText='Upload Additional Images'
                    setImageUploading={setImageUploading}
                    initialValues={albumImages || raffle?.additionalImages}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PrizeForm prizes={raffle?.prizes ?? []} multipleWinners={raffle?.multipleWinners} />
                </Grid>
                <Grid item xs={12}>
                  <JustificationForm justifications={raffle?.justifications ?? []} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid={'startTime'}
                    defaultValue={convertUtcStringToCurrentTimezone(raffle?.startTime)?.split('.')[0]}
                    error={errors.raffleDateTime}
                    helperText={errors.raffleDateTime && errors.raffleDateTime?.message}
                    fullWidth
                    id='raffleDateTime'
                    name='raffleDateTime'
                    inputRef={register({
                      required: 'Raffle start date and time is required',
                    })}
                    label='Raffle date and time'
                    variant='outlined'
                    type={'datetime-local'}
                    min={new Date()}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={isLive}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    data-testid={'ticketQuantity'}
                    defaultValue={raffle ? raffle.ticketQuantity : ''}
                    fullWidth
                    error={errors.ticketQuantity}
                    helperText={errors.ticketQuantity && errors.ticketQuantity?.message}
                    id='ticketQuantity'
                    label='# of tickets'
                    name='ticketQuantity'
                    type={'number'}
                    variant='outlined'
                    inputRef={register({
                      required: '# of tickets is required',
                    })}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    data-testid={'ticketPrice'}
                    defaultValue={raffle?.ticketPrice}
                    error={errors.ticketPrice}
                    helperText={errors.ticketPrice && errors.ticketPrice?.message}
                    fullWidth
                    id='ticketPrice'
                    inputRef={register({
                      required: 'ticket price is required',
                    })}
                    label='Price per ticket'
                    name='ticketPrice'
                    type={'number'}
                    inputProps={{ step: 'any' }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid={'location'}
                    error={errors.location}
                    helperText={errors.location && errors.location?.message}
                    defaultValue={raffle?.location}
                    fullWidth
                    id='location'
                    inputRef={register({
                      required: 'location is required',
                    })}
                    label='Location'
                    name='location'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid={'shipping'}
                    error={errors.shipping}
                    helperText={errors.shipping && errors.shipping?.message}
                    defaultValue={raffle?.shipping}
                    fullWidth
                    field={'shipping'}
                    id='shipping'
                    inputRef={register({
                      required: 'shipping is required',
                    })}
                    label='Shipping'
                    name='shipping'
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid={'paymentInfo'}
                    error={errors.paymentInfo}
                    helperText={errors.paymentInfo && errors.paymentInfo?.message}
                    defaultValue={raffle?.paymentInfo}
                    fullWidth
                    id='paymentInfo'
                    inputRef={register({
                      required: 'payment info is required',
                    })}
                    label='Payment info'
                    name='paymentInfo'
                    type={'text'}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                  <TextField
                    data-testid={'escrow'}
                    error={errors.escrow}
                    helperText={errors.escrow && errors.escrow?.message}
                    defaultValue={raffle?.escrow}
                    fullWidth
                    id='escrow'
                    inputRef={register({
                      required: 'escrow is required',
                    })}
                    label='Escrow'
                    name='escrow'
                    variant='outlined'
                  />
                </Grid>
                {(!raffle || raffle?.draft) && (
                  <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                    <Button
                      onClick={handleSaveForLater}
                      fullWidth
                      color='secondary'
                      variant='contained'
                      disabled={imageUploading}>
                      Save for later
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                  <Button type='submit' fullWidth color='primary' variant='contained' disabled={imageUploading}>
                    {raffle && !raffle.draft ? 'Update Raffle' : 'Publish raffle'}
                  </Button>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                  <Button
                    fullWidth
                    onClick={history.goBack}
                    color='primary'
                    style={{ marginBottom: '1rem' }}
                    variant='outlined'>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Paper>
      </Container>
    </Loader>
  );
};

export default withRouter(RaffleForm);
