import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import ConversationList from '../chat/conversationList';
import ChatList from '../chat/chatList';
import { useChat } from '../../hooks/useChat.js';
import { useForm } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { useOutsideClickTracker } from '../../hooks/useOutsideClickTracker';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    zIndex: '1201',
    bottom: '6rem',
    right: '1rem',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '330px',
    },
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: '70vh',
    overflowY: 'auto',
  },
}));

const ChatWindow = ({ closeChat, chatButtonRef }) => {
  const classes = useStyles();
  const { conversations, activeChat, sendMessage } = useChat();
  const { handleSubmit, register, reset, errors, watch } = useForm();
  const [conversationList, setConversationList] = React.useState([]);
  const [ignoreOutsideClick, setIgnoreOutsideClick] = React.useState(false);
  const conversationFilter = watch('conversationFilter', '');
  const chatRef = useRef(null);
  const conversationListRef = useRef(null);
  useOutsideClickTracker(chatRef, closeChat, [chatButtonRef, conversationListRef], ignoreOutsideClick);

  useEffect(() => {
    const convoList =
      conversations?.filter(
        (convo) => convo.withUser.toUpperCase().includes(conversationFilter.toUpperCase()) || !conversationFilter,
      ) || [];
    if (activeChat && !convoList.some((convo) => convo.withUser === activeChat?.withUser)) {
      convoList.push({ withUser: activeChat?.withUser, conversation: [] });
    }
    setConversationList(convoList);
  }, [activeChat, conversationFilter, conversations]);

  const handleSendMessage = (formvals) => {
    sendMessage(activeChat?.withUser, formvals.message);
    reset();
  };

  return (
    <form ref={chatRef} onSubmit={handleSubmit(handleSendMessage)}>
      <div className={classes.root}>
        <Grid container component={Paper} className={classes.chatSection}>
          <Grid item xs={12} className={classes.borderRight500} innerRef={conversationListRef}>
            <Grid container>
              <Grid item xs={12}>
                <ConversationList openCloseCallback={setIgnoreOutsideClick} conversations={conversationList} />
              </Grid>
            </Grid>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <ChatList messages={activeChat?.conversation} />
            <Divider />
            <Grid container style={{ padding: '20px' }}>
              <Grid item xs={9}>
                <TextField
                  inputRef={register({
                    required: 'Message is required',
                  })}
                  fullWidth
                  id='message'
                  label='Type Message'
                  name='message'
                  error={!!errors.message}
                  helperText={errors.message && errors.message?.message}
                  disabled={!activeChat}
                />
              </Grid>
              <Grid item xs={3} align='right'>
                <Box m={'12px'}>
                  <Fab type='submit' disabled={!activeChat} color='primary' aria-label='send'>
                    <SendIcon type='submit' />
                  </Fab>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};

export default ChatWindow;
