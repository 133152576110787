import { Divider } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Alert from '../../components/alert';
import ForgotPasswordModal from '../../components/modals/forgotPasswordModal';
import { useAuth } from '../../hooks/useAuth';
import { useStyles } from '../../hooks/useStyles';

export const LoginPage = ({ history, location }) => {
  const { handleSubmit, register, errors } = useForm();
  const { login, loggedIn } = useAuth();
  const [loginErr, setLoginErr] = useState();
  const [alertMessage, setAlertMessage] = useState();
  const styles = useStyles();

  const handleLoginError = (err) => {
    console.error(err);
    setLoginErr(err.description);
  };

  const handleLogin = (values) => {
    //auth0 will check if the username looks like an email and use it as an email if it does
    login({ username: values.usernameEmail, password: values.password }, handleLoginError);
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (loggedIn && queryParams.redirect) {
      history.push(queryParams.redirect);
    }
    if (queryParams.status) {
      if (queryParams.status === 'registration-success-verify-email') {
        setAlertMessage(
          'You have successfully created an account, however before you can login you need to verify your email',
        );
      }
      if (queryParams.status === 'password-reset-sent') {
        setAlertMessage('Check email to reset your password');
      }
    }
  }, [loggedIn, history, location.search]);

  return (
    <Container maxWidth='sm'>
      <Paper elevation={5} className={`${styles.paper} `}>
        <Box mt={2}>
          <Typography component='h1' variant='h5'>
            Login
          </Typography>
          <Alert show={!!loginErr} severity='error' data-testid={'alert-error'}>
            Error logging in: {loginErr}
          </Alert>
          <Alert show={!!alertMessage} severity='success' data-testid={'alert-registration-success'}>
            {alertMessage}
          </Alert>
        </Box>

        <form onSubmit={handleSubmit(handleLogin)}>
          <TextField
            autoComplete='usernameEmail'
            autoFocus
            error={!!errors.usernameEmail}
            fullWidth
            helperText={errors.usernameEmail?.message}
            id='usernameEmail'
            inputProps={{
              autoCapitalize: 'none',
              autoCorrect: 'off',
            }}
            inputRef={register({
              required: 'Username/Email is required',
            })}
            label='Username/Email'
            margin='normal'
            name='usernameEmail'
            variant='outlined'
          />
          <TextField
            autoComplete='current-password'
            error={!!errors.password}
            fullWidth
            helperText={errors.password?.message}
            id='password'
            inputRef={register({
              required: 'Password is required',
            })}
            label='Password'
            margin='normal'
            name='password'
            type='password'
            variant='outlined'
          />
          <Button type='submit' fullWidth variant='contained' color='primary' style={{ marginBottom: '1rem' }}>
            LOGIN
          </Button>
        </form>
        <Grid container style={{ textAlign: 'center' }}>
          <Grid item style={{ width: '100%', marginBottom: '1rem' }}>
            <Divider />
          </Grid>
          <Grid item style={{ width: '100%', marginBottom: '1rem' }}>
            <ForgotPasswordModal />
          </Grid>
          <Grid item style={{ width: '100%', marginBottom: '1rem' }}>
            <Link to='/registration'>Don't have an account? Register for one now.</Link>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default LoginPage;
