import { Container, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <Container style={{ textAlign: 'center', marginTop: '1rem' }}>
      <Typography component='h1' variant='h3'>
        Sorry, there was an unexpected error.
      </Typography>
      <Typography component='p' variant='body1' style={{ textAlign: 'center', marginTop: '1rem' }}>
        Return to <Link to='/'>home page</Link>.
      </Typography>
    </Container>
  );
};

export default ErrorPage;
