import React from 'react';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
  return (
    <div data-testid={'page-not-found'}>
      <p>
        Page Not Found! Return to <Link to='/login'>login</Link>.
      </p>
    </div>
  );
};

export default PageNotFound;
