import { Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import PrizeCard from './prizeCard';

const PrizesDisplay = ({ prizes }) => {
  return (
    <>
      <Typography variant='h5' component='h2' style={{ textAlign: 'center', paddingBottom: '2rem' }}>
        Prizes
      </Typography>
      <Grid container spacing={2} direction='row' justify='flex-start' alignItems='flex-start'>
        {prizes?.map((prize, index) => (
          <Grid item sm={12} md={prizes?.length > 1 ? 6 : 12} key={prize.id || index}>
            <PrizeCard prize={prize} />
          </Grid>
        ))}
      </Grid>
      <Divider style={{ marginTop: '2rem' }} />
    </>
  );
};

export default PrizesDisplay;
