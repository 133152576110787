import React, { Fragment } from 'react';
import { Box, Grid } from '@material-ui/core';
import TicketCheckbox from '../ticketCheckbox';

const TicketGrid = ({ tickets }) => {
  return (
    <Box mt={4}>
      <Grid container justify='flex-start' alignItems='flex-start' spacing={0}>
        {tickets?.map((ticket) => {
          return (
            <Fragment key={ticket.number}>
              {ticket.number % 10 === 1 && <Grid item xs={1} />}
              <Grid item xs={1}>
                <TicketCheckbox ticketNumber={ticket.number} owner={ticket.owner} />
              </Grid>
              {ticket.number % 10 === 0 && <Grid item xs={1} />}
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
};

export default TicketGrid;
