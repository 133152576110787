import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../../components/alert';
import { useAuth } from '../../hooks/useAuth';
import { useStyles } from '../../hooks/useStyles';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import CircularProgress from '@material-ui/core/CircularProgress';
import { REGEX_PATTERN } from '../../utils/constants';
import { emailTest } from '../../utils/dataUtils';

export const Registration = ({ history, location }) => {
  const { handleSubmit, register, errors } = useForm();
  const { registerUser } = useAuth();
  const styles = useStyles();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const submitRegistration = async (values) => {
    setIsLoading(true);
    const regObj = {
      username: values.username,
      email: values.email,
      phone: values.phone,
      password: values.password,
    };
    await registerUser(regObj)
      .then(() => {
        history.push('/login?status=registration-success-verify-email');
      })
      .catch((error) => {
        console.error(error);
        // Can expand out for other error scenarios in future
        setError(error.description === 'Invalid sign up' && 'Username / Email already in use');
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress data-testid={'loader'} />
      ) : (
        <>
          <Container component='main' maxWidth='sm'>
            <Paper elevation={5} className={`${styles.paper} `}>
              <div>
                <Typography component='h1' variant='h5' style={{ margin: '1rem 0rem' }}>
                  Register for ZapRaffle
                </Typography>
                <Alert show={!!error} severity='error' style={{ marginBottom: '1rem' }}>
                  <b>Error when attempting to register:</b> {error}
                </Alert>
                <form noValidate onSubmit={handleSubmit(submitRegistration)}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete='username'
                        autoFocus
                        data-testid={'username'}
                        error={errors.username}
                        fullWidth
                        id='username'
                        inputRef={register({
                          maxLength: { value: 20, message: 'Max length is 20 characters' },
                          minLength: { value: 3, message: 'Min length is 3 characters' },
                          pattern: { value: REGEX_PATTERN.REG_USERNAME, message: 'Invalid username character' },
                          required: 'Username is required',
                        })}
                        label='Username'
                        name='username'
                        required
                        variant='outlined'
                      />
                      <ErrorMessage
                        as='p'
                        data-testid={'username-error'}
                        errors={errors}
                        name='username'
                        style={{ color: 'red', fontWeight: 400 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete='email'
                        data-testid={'email'}
                        error={errors.email}
                        fullWidth
                        id='email'
                        inputRef={register({
                          required: 'Email is required',
                          validate: (value) => emailTest(value),
                        })}
                        label='Email Address'
                        name='email'
                        required
                        variant='outlined'
                      />
                      <ErrorMessage
                        as='p'
                        data-testid={'email-error'}
                        errors={errors}
                        name='email'
                        style={{ color: 'red', fontWeight: 400 }}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginBottom: '1rem' }}>
                      <TextField
                        autoComplete='password'
                        data-testid={'password'}
                        error={errors.password}
                        fullWidth
                        id='password'
                        inputRef={register({
                          required: 'Password is required',
                        })}
                        label='Password'
                        name='password'
                        required
                        type='password'
                        variant='outlined'
                      />
                      <ErrorMessage
                        as='p'
                        data-testid={'password-error'}
                        errors={errors}
                        name='password'
                        style={{ color: 'red', fontWeight: 400 }}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    color='primary'
                    data-testid={'submit-btn'}
                    fullWidth
                    type='submit'
                    variant='contained'
                    style={{ marginBottom: '1rem' }}>
                    Sign Up
                  </Button>
                  <Grid container justify='center'>
                    <Grid item>
                      <Link data-testid={'link-to-login'} to='/login'>
                        Already have an account? Log in
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Paper>
          </Container>
        </>
      )}
    </>
  );
};

export default Registration;
