import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  'mt-1': {
    marginTop: theme.spacing(1),
  },
  'mt-2': {
    marginTop: theme.spacing(2),
  },
  'mb-1': {
    marginBottom: theme.spacing(1),
  },
  'mb-2': {
    marginBottom: theme.spacing(2),
  },
  dateInput: {
    border: '1px gray solid',
    height: '50px',
    borderRadius: '5px',
    fontSize: '20px',
    paddingRight: '21px',
    paddingLeft: '21px',
  },
  timeInput: {
    border: '1px gray solid',
    height: '50px',
    borderRadius: '5px',
    fontSize: '20px',
    paddingRight: '21px',
    paddingLeft: '21px',
  },
}));
