import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Alert from '../../components/alert';
import Loader from '../../components/loader';
import { useAuth } from '../../hooks/useAuth';
import useHttpClient from '../../hooks/useHttpClient';
import DeleteRaffleModal from './DeleteRaffleModal';
import { convertUtcStringToFriendlyCurrentTimezone } from '../../utils/dataUtils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  box: {
    display: 'flex',
    border: '1px solid gray',
    borderRadius: '5px',
    flexDirection: 'row',
    padding: '0.5rem 0.5rem',
    margin: '0.5rem 0',
    justifyContent: 'flex-start',
    flex: 1,
  },
  header: {
    fontSize: '1rem',
    fontWeight: '400',
  },
  subHeader: {
    fontSize: '0.75rem',
    fontWeight: '300',
  },
  subHeaderTitle: {
    fontSize: '0.75rem',
    fontWeight: '400',
    display: 'flex',
    padding: '0.25rem 0',
  },
  body: {
    fontSize: '0.75rem',
    fontWeight: '400',
  },
}));

const RaffleAdmin = () => {
  const [alertMessage, setAlertMessage] = React.useState(false);
  const classes = useStyles();
  const { user } = useAuth();
  const location = useLocation();

  const { data: raffles, error, loading } = useHttpClient({
    url: `/raffle-service/raffles?userId=${encodeURIComponent(user.username)}&summary=true`,
    initialRequest: true,
  });

  React.useEffect(() => {
    const queryParams = queryString.parse(location.search);

    switch (queryParams.status) {
      case 'raffle-saved-for-later':
        setAlertMessage('Raffle has been saved for later');
        break;
      case 'raffle-updated':
        setAlertMessage('Raffle has been updated!');
        break;
      case 'raffle-created':
        setAlertMessage('Raffle has been created!');
        break;
      case 'raffle-canceled':
        setAlertMessage('Raffle has been canceled!');
        break;
      case 'raffle-deleted':
        setAlertMessage('Raffle has been deleted!');
        break;
      default:
        break;
    }
  }, [location]);

  return (
    <Container component='main' maxWidth='lg'>
      <Loader loading={loading}>
        <Typography component='h1' variant='h5' style={{ margin: '1rem 0rem' }}>
          Managed Raffles
        </Typography>
        <Paper style={{ padding: '1rem' }}>
          <Alert show={alertMessage} severity={'info'}>
            <b>{alertMessage}</b>
          </Alert>
          <Alert show={error} severity={error} style={{ marginBottom: '1rem' }}>
            <b>{error}</b>
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {raffles?.map((raffle, i) => {
                return (
                  <div key={i + raffle.id} className={classes.box}>
                    <Grid container spacing={1} direction='row'>
                      <Grid item sm={12} md={6} alignItems='left'>
                        <div>
                          <div className={classes.header}>{`${raffle.title}`}</div>
                          <div className={classes.subHeaderTitle}>
                            ID:
                            <div className={classes.subHeader}>{`${raffle.id}`}</div>
                          </div>
                          <div className={classes.body}>{`Starts at ${convertUtcStringToFriendlyCurrentTimezone(
                            raffle.startTime,
                          )}`}</div>
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={'auto'} alignMiddle>
                        <Button
                          color={'primary'}
                          component={RouterLink}
                          to={`/raffles/manage/${raffle.id}`}
                          variant={'text'}>
                          Manage Raffle
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={'auto'}>
                        <Button
                          color={'secondary'}
                          variant={'text'}
                          size={'small'}
                          component={RouterLink}
                          to={`/raffles/edit/${raffle.id}`}>
                          Edit
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={'auto'}>
                        <DeleteRaffleModal raffle={raffle} />
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      </Loader>
    </Container>
  );
};

export default RaffleAdmin;
