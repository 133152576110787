import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import { useAuth } from '../../../hooks/useAuth';
import Loader from '../../loader';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    width: 'fit-content',
    minWidth: 125,
    minHeight: 125,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const ForgotPasswordModal = ({ history, location }) => {
  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
  });
  const { resetPassword } = useAuth();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emailSent, setEmailSent] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmailSent(false);
  };

  const submitPasswordReset = async (values) => {
    setIsLoading(true);
    resetPassword(values)
      .then(() => {
        setEmailSent(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const body = () => {
    return (
      <>
        <div className={classes.paper}>
          <Loader loading={isLoading}>
            <form id={'password-reset-form'} noValidate onSubmit={handleSubmit(submitPasswordReset)}>
              <h2 id='reset-password-modal-title'>Forgot Password</h2>
              {emailSent ? (
                <React.Fragment>
                  <p>Please check your email for a password reset email.</p>
                  <Button
                    color='primary'
                    data-testid={'close-btn'}
                    fullWidth
                    type={'close'}
                    onClick={handleClose}
                    variant='contained'
                    style={{ marginBottom: '1rem' }}>
                    Close
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <p>Enter your email here and we will send you an email to reset your password.</p>
                  <TextField
                    autoComplete='email'
                    autoFocus
                    data-testid={'email'}
                    error={errors.email}
                    fullWidth
                    id='email'
                    inputRef={register({
                      required: 'Email is required',
                    })}
                    style={{ marginBottom: '1rem' }}
                    label='Email '
                    name='email'
                    required
                    variant='outlined'
                  />
                  <Button
                    color='primary'
                    data-testid={'submit-btn'}
                    fullWidth
                    type={'submit'}
                    form={'password-reset-form'}
                    variant='contained'
                    style={{ marginBottom: '1rem' }}>
                    Submit
                  </Button>
                  <Button
                    color='primary'
                    fullWidth
                    type={'close'}
                    onClick={handleClose}
                    variant='outlined'
                    style={{ marginBottom: '1rem' }}>
                    Cancel
                  </Button>
                </React.Fragment>
              )}
            </form>
          </Loader>
        </div>
      </>
    );
  };

  return (
    <div>
      <Link onClick={handleOpen} to={'#'}>
        Forgot Password?
      </Link>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='forgot-password-modal-title'
        aria-describedby='forgot-password-modal-description'>
        {body()}
      </Modal>
    </div>
  );
};

export default ForgotPasswordModal;
