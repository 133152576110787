import { Box, Button, FormGroup, MenuItem, Paper, TextField } from '@material-ui/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import SelectInput from '../selectInput';

const PrizeFields = ({ prize, removePrize, listId, showRemove }) => {
  const { register, unregister, errors, control } = useFormContext();

  const handleClickRemove = () => {
    unregister(`prizeDescription[${listId}]`);
    unregister(`prizeTitle[${listId}]`);
    removePrize(listId);
  };

  return (
    <>
      <Box width='75%'>
        <Paper elevation={3} style={{ padding: '1rem', marginBottom: '1rem' }}>
          {/* this is a hidden field to maintain the prize ID if it isnt new */}
          <TextField
            inputRef={register()}
            defaultValue={prize.id}
            id={`prizeId[${listId}]`}
            name={`prizeId[${listId}]`}
            style={{ display: 'none' }}
          />
          <FormGroup row>
            <TextField
              inputRef={register()}
              defaultValue={prize.title}
              variant='outlined'
              margin='normal'
              id={`prizeTitle[${listId}]`}
              label='Prize Title'
              name={`prizeTitle[${listId}]`}
              error={!!errors?.prizeTitle?.[listId]}
              helperText={errors?.prizeTitle?.[listId] && errors?.prizeTitle?.[listId]?.message}
              style={{ width: '50%', marginRight: '1rem' }}
            />
            <SelectInput
              id={`prizeCategory[${listId}]`}
              name={`prizeCategory[${listId}]`}
              defaultValue={prize.prizeCategory}
              label='Prize category'
              control={control}
              variant='outlined'
              margin='normal'
              style={{ width: 'calc(50% - 1rem)' }}>
              <MenuItem value='WATCH'>Watch</MenuItem>
              <MenuItem value='KNIFE'>Knife</MenuItem>
              <MenuItem value='LEGO'>Lego</MenuItem>
              <MenuItem value='CAMERA'>Camera</MenuItem>
              <MenuItem value='CARRY_BAG'>Carry Bag</MenuItem>
              <MenuItem value='HEADPHONES'>Headphones</MenuItem>
              <MenuItem value='PEN'>Pen</MenuItem>
              <MenuItem value='SUNGLASSES'>Sunglasses</MenuItem>
              <MenuItem value='VIDEO_GAME'>Video Game</MenuItem>
              <MenuItem value='WALLET_PURSE'>Wallet/Purse</MenuItem>
              <MenuItem value='OTHER'>Other</MenuItem>
            </SelectInput>
          </FormGroup>
          <TextField
            inputRef={register()}
            defaultValue={prize.description}
            variant='outlined'
            margin='normal'
            id={`prizeDescription[${listId}]`}
            label='Prize Description'
            name={`prizeDescription[${listId}]`}
            error={!!errors?.prizeDescription?.[listId]}
            helperText={errors.prizeDescription?.[listId] && errors.prizeDescription?.[listId]?.message}
            fullWidth={true}
            multiline={true}
            rows={4}
          />
          {showRemove && (
            <Button onClick={handleClickRemove} color='primary' variant='contained'>
              remove
            </Button>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default PrizeFields;
